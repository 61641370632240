import Auth from "../components/authentication/Auth";
import dayjs from "dayjs";

class EWAs {
    static async loadEWAs(queryObj){
        const limit = 50;
        let skip = 0;
        let data = [];
        // while(true){
        let dbData;
        if(queryObj){
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpoint("GET", "EWAS", `selector=three&jsonString=${jsonString}&skip=${skip}&limit=${limit}`);
        }
        else{
            dbData = await Auth.hitEndpoint("GET", "EWAS", `selector=two&skip=${skip}&limit=${limit}`);
        }
        data = [...data, ...dbData];
        if(dbData.length === 0){
            // break;
        }
        skip += limit;
        // }
        // Convert all dates from strings to date objects
        data.forEach(ewa => {
            ewa.editedBy = {
                date: new Date(ewa.modified_at)
            };
        })

        return data;
    }

    static async loadEWACodes(queryObj) {
        let data = [];
        let dbData;
        if(queryObj === null){
            queryObj = {
                type: "ewa",
            }
        }
        else {
            queryObj['type'] = "ewa"
        }

        if (queryObj) {
            const jsonString = JSON.stringify(queryObj);
            dbData = await Auth.hitEndpointNew("GET", "phase-tasks", `jsonString=${jsonString}`);
        } else {
            dbData = await Auth.hitEndpointNew("GET", "phase-tasks", ``);
        }
        data = [...data, ...dbData];

        return data;
    }

    static async saveNewEWA(ewa){
        const result = await Auth.hitEndpoint("POST", "EWA", "", ewa);
        ewa.id = result.id;
        return ewa;
    }

    static async updateEWA(ewa, user){
        await Auth.hitEndpointNew("PATCH", "ewas", "", ewa);
        return ewa;
    }

    static async deleteEWA(ewa){
        await Auth.hitEndpoint("DELETE", "EWAS", "", ewa);
        return ewa;
    }

    static async saveEWAs(ewas){
        const result = await Auth.hitEndpointNew("POST", "ewas", "", ewas);
        return result;
    }

    static async saveNewEWAsFromSchedule(ewas){
        // for(let e=0; e<ewas.length; e++){
        //     ewas[e].line_items = ewas[e].line_items
        // }
        return await Auth.hitEndpointNew("POST", "ewas", "", ewas);
    }

    static async updateEWAs(ewa, user){
        await Auth.hitEndpointNew("PATCH", "ewas", "", ewa);
        return ewa;
    }

    static async createNewEWA(lot){
        let userId = await Auth.getUserId()
        return {
            number: '',
            ewa_type_id: 1,
            approved: false,
            approval_by: null,
            approval_at: null,
            internal_estimate_number: '',
            builder_po_number: '',
            line_items: [
                {
                    lot_id: lot === null ? null : lot.id,
                    phase_id: '',
                    phase_task_id: '',
                    schedule_date: null,
                    scheduled_by: userId,
                    payout: 0,
                    approved: false,
                    approval_by: null,
                    approval_date: null,
                    complete: false,
                    completed_by: null,
                    completed_date: null,
                    notes: "",
                    scheduled: true,
                    requested: false,
                    crew: {
                        schedule_id: null,
                        crew_id: null,
                        employees: [{
                            employee_id: null,
                            is_lead: false,
                            approved: false,
                            approval_by: null,
                            approval_date: null,
                        }]
                    }
                }
            ],
            created_at: dayjs(new Date()).format('YYYY-MM-DD H:mm:ss'),
            created_by: userId,
            modified_at: null,
            modified_by: null,
            printed: false,
            printed_at: null,
            printed_by: null
        }
    }

    static async buildEWA(row){
        let ewa = {
            id: row.id,
            number: row.number,
            ewa_type_id: 1,
            approved: row.approved,
            approval_by: row.approval_by,
            approval_at: row.approval_at,
            internal_estimate_number: row.internal_estimate_number,
            builder_po_number: row.builder_po_number,
            created_at: dayjs(new Date(row.created_at)).format('YYYY-MM-DD H:mm:ss'),
            created_by: row.created_by,
            modified_at: row.modified_at,
            modified_by: row.modified_by,
            printed: row.printed,
            printed_at: row.printed_at,
            printed_by: row.printed_by
        }

        let lineItems = []
        for(let l=0; l<row.line_items.length; l++) {
            let line = row.line_items[l]
            lineItems.push(
                {
                    id: line.id,
                    lot_id: line.lot_id,
                    job_id: line.lot === '' ? null : line.lot.job.id,
                    job: line.lot === '' ? null : `${line.lot.job.number} ${line.lot.job.project_name}`,
                    lot: line.lot === '' ? null : `${line.lot.number}`,
                    address: line.lot === '' ? null : line.lot.address,
                    phase_id: line.phase_id,
                    phase_task_id: line.phase_task_id,
                    task: (`${line.task.code !== null ? line.task.code : ''} ${line.task.description}`).trim(),
                    schedule_date: dayjs(new Date(line.schedule_date)).format('YYYY-MM-DD'),
                    scheduled_by: line.scheduled_by,
                    payout: line.payout,
                    approved: line.approved,
                    approval_by: line.approval_by,
                    approval_date: line.approval_date,
                    complete: line.complete,
                    completed_by: line.completed_by,
                    completed_date: line.completed_at,
                    notes: line.notes,
                    scheduled: line.scheduled,
                    requested: line.requested,
                    internal_estimate_number: line.internal_estimate_number === undefined || line.internal_estimate_number === null || line.internal_estimate_number === '' ? row.internal_estimate_number : line.internal_estimate_number,
                    builder_po_number: line.builder_po_number === undefined || line.builder_po_number === null || line.builder_po_number === '' ? row.builder_po_number : line.builder_po_number,
                    crew: {
                        id: line.crew.id,
                        schedule_id: line.crew.schedule_id,
                        crew_id: line.crew.crew_id,
                        employees: line.crew.employees
                    }
                }
            )
        }
        ewa.line_items = lineItems
        return ewa
    }

    static async checkEWAForErrors(ewa, billable){
        if(ewa.line_items[0].phase_task_id === ''){return "ERROR_NO_PHASE_CODE";}
        if(ewa.line_items[0].notes === null || ewa.line_items[0].notes === ''){return "ERROR_NO_DESCRIPTION";}
        if(!ewa.line_items[0].schedule_date){return "ERROR_NO_SCHEDULED_DATE";}
        if(ewa.line_items[0].payout === null || ewa.line_items[0].payout === 0 || ewa.line_items[0].payout === '0.00'){return "ERROR_NO_PAYOUT_AMOUNT";}
        if(billable && (ewa.internal_estimate_number === null || ewa.internal_estimate_number === '')){return "ERROR_NO_INTERNAL_ESTIMATE";}
        if(billable && (ewa.builder_po_number === null || ewa.builder_po_number === '' )){return "ERROR_NO_BUILDER_PO";}
        if(ewa.line_items[0].crew.employees[0].employee_id === null || ewa.line_items[0].crew.employees[0].employee_id === ''){return "ERROR_NO_EMPLOYEE_SELECTED";}
        return "SUCCESS";
    }

    static async checkEWAForErrorsNew(ewaObj, billable){
        let ewaLineItem;
        if(ewaObj.newEWA !== undefined){
            ewaLineItem = ewaObj.newEWA;
        }
        else{
            ewaLineItem = ewaObj;
        }
        if(ewaLineItem.lot_id === ''){return "ERROR_NO_LOT_SELECTED";}
        if(ewaLineItem.line_items[0].phase_task_id === ''){return "ERROR_NO_PHASE_CODE";}
        if(ewaLineItem.line_items[0].notes === null || ewaLineItem.line_items[0].notes === ''){return "ERROR_NO_DESCRIPTION";}
        if(billable && (ewaLineItem.internal_estimate_number === null || ewaLineItem.internal_estimate_number === '')){return "ERROR_NO_INTERNAL_ESTIMATE";}
        if(billable && (ewaLineItem.builder_po_number === null || ewaLineItem.builder_po_number === '')) {return "ERROR_NO_BUILDER_PO";}
        if(ewaLineItem.line_items[0].schedule_date === null){return "ERROR_NO_SCHEDULED_DATE";}
        if(ewaLineItem.line_items[0].payout === null || ewaLineItem.line_items[0].payout === 0 || ewaLineItem.line_items[0].payout === '0.00'){return "ERROR_NO_PAYOUT_AMOUNT";}
        if(ewaLineItem.line_items[0].crew === undefined || ewaLineItem.line_items[0].crew === null || ewaLineItem.line_items[0].crew.employees[0] === null || ewaLineItem.line_items[0].crew.employees[0].employee_id === null || ewaLineItem.line_items[0].crew.employees[0].employee_id === ''){return "ERROR_NO_EMPLOYEE_SELECTED";}
        return "SUCCESS";
    }

    static convertEWASDates(ewas){
        ewas.forEach(ewa => {
            ewa.printed_at = ewa.printed_at ? new Date(ewa.printed_at) : null;
            ewa.created_at = ewa.created_at ? new Date(ewa.created_at) : null;
            ewa.modified_at = ewa.modified_at ? new Date(ewa.modified_at) : null;
            ewa.approval_at = ewa.approval_at ? new Date(ewa.approval_at) : null;
            ewa.line_items.forEach(line => {
                line.schedule_date = line.schedule_date ? new Date(line.schedule_date) : null;
                line.approval_at = line.approval_at ? new Date(line.approval_at) : null;
                line.completed_at = line.completed_at ? new Date(line.completed_at) : null;
            })
            ewa.employees.forEach(employee => {
                employee.approval_at = employee.approval_at ? new Date(employee.approval_at) : null;
            })
        })
    }

    static getExpandedLotsEWAArray(lots){
        const lotsExpanded = [];
        for(let i = 0; i < lots.length; i++){
            for(let j = 0; j < lots[i].ewas.length; j++){
                lots[i].currentEWAIndex = j;
                lotsExpanded.push({...lots[i]});
            }
        }
        return lotsExpanded;
    }

    static async getSelectedEwaCodeIds(codes){
        let ewaCodeIds = []
        if (codes !== undefined && codes !== null && codes.length > 0) {
            codes.forEach(s => {
                ewaCodeIds.push(s.value)
            })
        }
        return ewaCodeIds
    }

    static async getNextNumber(company, suffix, year, number){
        let queryString = JSON.stringify({
            "phase.company": company,
            "phase.ewa_suffix": suffix
        })
        if(number === undefined || number.raw === undefined) {
            let rawNumber = await Auth.hitEndpointNew("GET", "ewa/last", queryString)
            number = {
                raw: rawNumber
            }
        }
        else{
            number.raw += 1
        }
        let ewaNumber = String(number.raw + 1).padStart(5, '0')


        return {
            raw: number.raw,
            ewa_number:  `${company}${ewaNumber}${suffix}-${year}`
        }
    }


    // Below is edit functions for individual ewa data
}

export default EWAs;
