import { useState } from 'react';
import Select, {createFilter} from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {filterConfig} from "./FilterConfig";

const LotSelect = ({lots, selectedLot, selectLot}) => {
    const [copied, setCopied] = useState(false);

    // const handleChange = (selectedOption) => {
    //   //  setSelectedValue(selectedOption);
    //     setCopied(false); // Reset copied state when selection changes
    // };

    return (
        <div>
            <Select  className="lot-drop-down-select" id='lot-form-select' name='lot-form-select'
                styles={{
                    container: styles => {
                        return {
                            ...styles,
                            zIndex: '50'
                        }
                    }
                }}
                onChange={(e) => selectLot(e)}
                options={lots.map(lot => {
                    const value = lot.id;
                    const display = `${lot.number} - ${lot.phase} - ${lot.address}`;
                    return (
                        {
                            value: value,
                            id: value,
                            label: display,
                            lot: lot
                        }
                    )
                })}
                value={selectedLot}
                isClearable
                isSearchable
                filterOption={createFilter(filterConfig)}
                label="Select a Lot"
                placeholder={"Select a Lot"}
            />
            {selectedLot && (
                <CopyToClipboard text={`${selectedLot.lot.address}`} onCopy={() => setCopied(true)}>
                    <button>
                        {copied ? 'Copied!' : 'Copy'}
                    </button>
                </CopyToClipboard>
            )}
        </div>
    );
};

export default LotSelect;