import { useState } from 'react';

import Auth from '../authentication/Auth.js';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button  from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select, {createFilter} from "react-select";
import PhaseCodesContainer from './styles/PhaseCodesContainer';

const PhaseCodes = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [phases, setPhases] = useState([]);
    const [phaseTasks, setPhaseTasks] = useState([]);

    const [phaseCode, setPhaseCode] = useState({
        id: '',
        name: '',
        code: '',
        type: null,
        phases: [],
    });

    const [errorPhaseCode, setErrorPhaseCode] = useState('');

    const phaseTypes = [{value: 'work', label: 'Work'}, {value: 'ewa', label: 'EWA'}]

    const loadData = async () => {
        setDidLoadData(true);

        setPhaseTasks(await Auth. hitEndpointNew("GET", "phase-tasks"));
        setPhases(await Auth.hitEndpointNew("GET", "phases"));
    }

    if(!didLoadData){
        loadData();
    }

    const handleSelectPhaseCodeToEdit = (e) => {
        if(e === null){
            setPhaseCode({
                id: '',
                name: '',
                code: '',
                type: null,
                phases: [],
            });
            return;
        }
        const phaseTask = phaseTasks.find(pt => parseInt(pt.id) === parseInt(e.value));
        setPhaseCode({
            id: phaseTask.id,
            name: phaseTask.name,
            code: phaseTask.code,
            type: phaseTypes.find(pt => pt.value === phaseTask.type),
            phases: phaseTask.phases.map(p => ({value: p.id, label: p.description})),
        });
    }

    const createPhaseCode = async (value) => {
        if(phaseCode.name === ''){
            setErrorPhaseCode('NO_NAME');
            return;
        }
        if(phaseCode.code === ''){
            setErrorPhaseCode('NO_CODE');
            return;
        }
        if(phaseCode.type === null){
            setErrorPhaseCode('NO_TYPE');
            return;
        }
        if(phaseCode.phases.length === 0){
            setErrorPhaseCode('NO_PHASES');
            return;
        }

        if(phaseTasks.id === '' && phaseTasks.find(pt => pt.code === phaseCode.code) !== undefined){
            setErrorPhaseCode('REPEAT_CODE');
        }

        const dbObj = {
            code: phaseCode.code,
            description: phaseCode.name,
            id: phaseCode.id,
            name: phaseCode.name,
            phaseTaskPhases: phaseCode.phases.map(p => {
                return {
                    phase_id: p.value
                }
            }),
            type: phaseCode.type.value,
        }

        if(dbObj.id !== ''){
            await Auth.hitEndpointNew("PATCH", "phase-tasks", "", dbObj)
        }
        else{
            delete dbObj.id;
            await Auth.hitEndpointNew("POST", "phase-tasks", "", dbObj)
        }

        setPhaseCode({
            id: '',
            name: '',
            code: '',
            type: '',
            phases: [],
        });
        setPhaseTasks(await Auth. hitEndpointNew("GET", "phase-tasks"));
    }

    return (
        <PhaseCodesContainer>
            <div className='phase-code-form'>
                <div className='form-header'>Phase Codes</div>
                <div className='edit-instructions'>Select a phase code to edit</div>
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '50px',
                                backgroundColor: 'white',
                                margin: '10px auto 40px',
                            }
                        },
                        placeholder: styles => {
                            return {
                                ...styles,
                            }
                        }
                    }} 
                    id="phase-code-id"
                    className="phase-code"
                    placeholder="Select Phase Code To Edit"
                    value={phaseCode.id !== '' ? {value: phaseCode.id, label: phaseCode.name} : null}
                    onChange={(e) => handleSelectPhaseCodeToEdit(e)}
                    options={phaseTasks.map(pt => ({value: pt.id, label: `${pt.code} - ${pt.name}`}))}
                    isClearable
                    isSearchable
                />
                <div className='create-instructions'>Enter phase code information</div>
                <TextField
                    sx={{
                        width: '100%',
                        margin: '10px auto',
                        zIndex: '0',
                        backgroundColor: errorPhaseCode === "NO_CODE" ? 'rgb(230, 100, 100)' : 'white',
                    }}
                    placeholder="Enter Phase Code"
                    label="Phase Code"
                    value={phaseCode.code}
                    onChange={(e) => setPhaseCode({...phaseCode, code: e.target.value})}
                />
                <TextField
                    sx={{
                        width: '100%',
                        margin: '10px auto',
                        zIndex: '0',
                        backgroundColor: errorPhaseCode === "NO_NAME" ? 'rgb(230, 100, 100)' : 'white',
                    }}
                    placeholder="Enter Phase Code Name"
                    label="Phase Code Name"
                    value={phaseCode.name}
                    onChange={(e) => setPhaseCode({...phaseCode, name: e.target.value})}
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '50px',
                                margin: '10px auto',
                                backgroundColor: errorPhaseCode === "NO_TYPE" ? 'rgb(230, 100, 100)' : 'white',
                            }
                        },
                        placeholder: styles => {
                            return {
                                ...styles,
                                color: errorPhaseCode === "NO_TYPE" ? 'black' : 'gray',
                            }
                        }
                    }} 
                    id="phase-code-type-id"
                    className="phase-code-type"
                    placeholder="Select Phase Code Type"
                    value={phaseCode.type}
                    onChange={(e) => setPhaseCode({...phaseCode, type: e})}
                    options={phaseTypes}
                    isClearable
                    isSearchable
                />
                <Select 
                    styles={{
                        control: styles => {
                            return {
                                ...styles,
                                height: '50px',
                                margin: '10px auto',
                                backgroundColor: errorPhaseCode === "NO_PHASES" ? 'rgb(230, 100, 100)' : 'white',
                            }
                        },
                        placeholder: styles => {
                            return {
                                ...styles,
                                color: errorPhaseCode === "NO_PHASES" ? 'black' : 'gray',
                            }
                        }
                    }} 
                    id="phase--id"
                    className="phase"
                    placeholder="Select Phase Code Phase(s)"
                    value={phaseCode.phases}
                    onChange={(e) => setPhaseCode({...phaseCode, phases: e})}
                    options={phases.map(p => ({value: p.id, label: p.description}))}
                    isMulti
                    isClearable
                    isSearchable
                />
                <Button className='submit-button' onClick={createPhaseCode}>Submit</Button>
            </div>

            <Dialog open={errorPhaseCode === "REPEAT_CODE"}>
                <DialogTitle>ERROR: Repeat Code</DialogTitle>
                <DialogContent>
                    {`Code already exists in database.`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorPhaseCode(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        </PhaseCodesContainer>
    );
}

export default PhaseCodes;