import { useState } from 'react';
import Lots from '../../classes/Lots.js';
import Utilities from '../../classes/Utilities'
import LotNotes from '../lots/LotNotes.js';
import Auth from "../authentication/Auth.js";
import moment from "moment";
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Can from '../authentication/Can.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const PaverMeasurements = (props) => {
    const [user, setUser] = useState('');

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [paverArrayIndex, setPaverArrayIndex] = useState(-1);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [didLoadData, setDidLoadData] = useState(false);
    const [success, setSuccess] = useState(false);

    const [measurements, setMeasurements] = useState([]);

    const inputPaverMeasurements = Can.isAuthorized('paverMeasurements', 'WRITE');

    let totalSF = 0;

    const loadData = async () => {
        setDidLoadData(true);
        setUser(`${await Auth.getUserId()}`);
        setMeasurements(await Utilities.loadMeasurements('1'))
    }

    if (!didLoadData) {
        loadData();
    }

    const lotSelected = async (lot) => {
        // Get selected lot
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());
        const tempJob = lot === null ? null : jobs.find(job => parseInt(job.id) === parseInt(tempSelectedLot.job_id));

        if(lot !== null && tempSelectedLot.hasPavers === undefined && tempJob.attributes.find(attribute => parseInt(attribute.attribute_id) === 10) !== undefined){
            if(tempJob.attributes.find(attribute => parseInt(attribute.attribute_id) === 11) === undefined){
                tempSelectedLot.attributes.push({
                    attribute_id: 10,
                    lot_id: tempSelectedLot.id
                })
                tempSelectedLot.hasPavers = 'true';
            }
        }

        let tempIndex = lot === null ? 0 : tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 8);
        if(tempIndex < 0){
            tempSelectedLot.phases.push({
                lot_id: tempSelectedLot.id,
                modified_at: new Date(),
                modified_by: await Auth.getUserId(),
                phase_id: 8,
                release_date: null,
                start_status_id: null,
                starts_status: "",
            });
            tempIndex = tempSelectedLot.phases.findIndex(p => parseInt(p.phase_id) === 8);
        }
        setPaverArrayIndex(tempIndex);

        return tempSelectedLot;
    }

    const updateMeasurement = (type, measurement) => {
        if(measurement === '' || parseInt(measurement) === 0){
            return;
        }
        let phases = selectedLot.phases
        if(phases[paverArrayIndex].starts_status === '' || phases[paverArrayIndex].starts_status === null 
            || phases[paverArrayIndex].starts_status === 'Need Measurements' || parseInt(phases[paverArrayIndex].starts_status_id) === 2){
            phases[paverArrayIndex].release_date = moment(new Date()).format('YYYY-MM-DD H:mm:ss');//(new Date()).toISOString();
            phases[paverArrayIndex].start_status_id = 1;
            phases[paverArrayIndex].starts_status = 'Received';
            phases[paverArrayIndex].modified_by = user;
        }
        setSelectedLot(current => {
            return{
                ...current,
                measurements: {
                    ...current.measurements,
                    pavers: {
                        ...current.measurements.pavers,
                        [type]: Number.parseInt(measurement === '' ? 0 : measurement),
                        user: user,
                        date: moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    }
                },
            }
        })
    }

    const handleSubmitMeasurements = async () => {
        let tempLot = {...selectedLot}
        delete tempLot.gate_information;
        await Lots.updateLot(tempLot, user);

        // Update lot in list of lots
        const index = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
        const tempLots = [...lots];
        tempLots[index] = tempLot;
        setLots([...tempLots]);
        setSuccess(true);
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
    }

    if (selectedLot && selectedLot.measurements !== undefined && Object.keys(selectedLot.measurements).length > 0 && Object.keys(selectedLot.measurements.pavers).length > 0) {
        totalSF = Number.parseInt(selectedLot.measurements.pavers.driveway) +
            Number.parseInt(selectedLot.measurements.pavers.walkway) +
            Number.parseInt(selectedLot.measurements.pavers.entry) +
            Number.parseInt(selectedLot.measurements.pavers.approach) +
            Number.parseInt(selectedLot.measurements.pavers.patio) +
            Number.parseInt(selectedLot.measurements.pavers.courtyard);
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <JobLotFilter
                title={'Paver Measurements'}
                jobs={jobs}
                setJobs={setJobs}
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                lots={lots}
                setLots={setLots}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
                lotSelected={lotSelected}
            />
            {selectedLot !== null && (selectedLot.hasPavers === 'true' || selectedLot.hasPavers === 'Opt') ? 
            <Box
                sx={{
                    background: 'rgb(210, 200, 190)',
                    padding: '0 0 15px',
                    margin: '3px 0 100px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderBottom: '3px solid white'
                    }}
                >Measurements</Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Release Date:</Box>
                    {selectedLot.phases[paverArrayIndex].release_date ?
                    <Box>{moment(new Date(selectedLot.phases[paverArrayIndex].release_date)).format('MM-DD-YYYY')}</Box> :
                    <Box>Not Released</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Starts Status:</Box>
                    {selectedLot.phases[paverArrayIndex].starts_status ?
                    <Box>{selectedLot.phases[paverArrayIndex].starts_status}</Box>:
                    <Box>N/A</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Lot Notes:</Box>
                    <Tooltip title="View/Edit Notes">
                        <TextSnippetIcon 
                            sx={{cursor: 'pointer'}}
                            onClick={() => handleDisplayNotes()}
                        />
                    </Tooltip>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Style:</Box>
                    {selectedLot.measurements.pavers.style !== '' ?
                    <Box>{selectedLot.measurements.pavers.style}</Box> :
                    <Box>N/A</Box>}
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Color:</Box>
                    {selectedLot.measurements.pavers.color !== '' ?
                    <Box>{selectedLot.measurements.pavers.color}</Box> :
                    <Box>N/A</Box>}
                </Box>
                {measurements.map(m => {
                    if(inputPaverMeasurements){
                        return (
                            <Box
                                key={m.id}
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    gridTemplateColumns: '1fr 1fr',
                                    margin: '10px 0',
                                }}
                            >
                                <Box
                                    sx={{
                                        alignSelf: 'center',
                                        textAlign: 'end',
                                        paddingRight: '5px'
                                    }}
                                >{m.description + ":"}</Box>
                                <TextField
                                    sx={{
                                        width: '80%',
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">sqft</InputAdornment>,
                                    }}
                                    size='small'
                                    value={selectedLot.measurements.pavers[m.name]}
                                    onChange={(e) => updateMeasurement(m.name, e.target.value)}
                                />
                            </Box>
                        )
                    }
                    return (
                        <Box
                            key={m.id}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                margin: '15px 0 20px',
                            }}
                        >
                            <Box
                                sx={{
                                    gridColumn: '1/3',
                                    alignSelf: 'center',
                                    textAlign: 'end',
                                    paddingRight: '5px'
                                }}
                            >{m.description + ":"}</Box>
                            <Box
                                sx={{
                                    gridColumn: '3/4',
                                    alignSelf: 'center',
                                    textAlign: 'end',
                                    paddingRight: '5px'
                                }}
                            >{selectedLot.measurements.pavers[m.name]}</Box>
                            <Box
                                sx={{
                                    gridColumn: '4/5',
                                    alignSelf: 'center',
                                    textAlign: 'start',
                                    paddingRight: '5px'
                                }}
                            >sqft</Box>
                        </Box>
                    )
                })}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        margin: '15px 0 20px',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '1/3',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Total:</Box>
                    <Box
                        sx={{
                            gridColumn: '3/4',
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >{totalSF}</Box>
                    <Box
                        sx={{
                            gridColumn: '4/5',
                            alignSelf: 'center',
                            textAlign: 'start',
                            paddingRight: '5px'
                        }}
                    >sqft</Box>
                </Box>
                {inputPaverMeasurements && 
                <Box
                    sx={{
                        display: 'grid',
                        justifyContent: 'center',
                        margin: '10px 0'
                    }}
                >
                    <Button variant='contained' onClick={handleSubmitMeasurements}>Submit Measurements</Button>
                </Box>}
            </Box> : 
            <Box
                sx={{
                    background: 'rgb(200, 200, 200)',
                    padding: '0 0 15px',
                    margin: '3px 0 100px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderBottom: '3px solid white'
                    }}
                >Measurements</Box>
                <Box
                    sx={{
                        color: 'rgb(245, 245, 245)',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            padding: '20px 40px'
                        }}
                    >
                        This lot does not require pavers. If this is incorrect or change is required, please contact the Starts Department
                    </Box>
                </Box>
            </Box>}
            {displayNotes &&
            <LotNotes
                openLotNotes={displayNotes}
                setOpenLotNotes={setDisplayNotes}
                lotToViewNotes={selectedLot}
                setLotToViewNotes={() => {}}
                noteType={'Paver'}
                updatedLotReturned={closeNotes}
            />}
            {success &&
            <Dialog open={success}>
                <DialogTitle sx={{backgroundColor: 'rgb(150, 255, 150)', fontWeight: 'bold'}}>Success</DialogTitle>
                <DialogContent 
                    sx={{width: "350px", backgroundColor: 'rgb(150, 255, 150)'}}>Paver Measurements saved successfully</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(150, 255, 150)'}}>
                    <Button 
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => setSuccess(false)}
                    >OK</Button>
                </DialogActions>
            </Dialog>}
        </FieldFormsContainer>
    )
}

export default PaverMeasurements;