import {useEffect, useMemo, useState} from 'react';

import EvenflowGrid from "./EvenflowGrid";
import EvenflowPageContainer from "./styles/EvenflowPageContainer";
import Select, {createFilter} from "react-select";
import Builders from "../../classes/Builders";
import Utilities from "../../classes/Utilities";
import Config from "../../config";
import Evenflow from "../../classes/Evenflow";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {darken, IconButton, lighten, Tab, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {MaterialReactTable, MRT_Row} from "material-react-table";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

import * as XLSX from 'sheetjs-style';
import { utils, writeFileXLSX } from "xlsx";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Schedules from "../../classes/Schedules";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import evenflow from "../../classes/Evenflow";

const EvenflowPage = (props) => {

    const [selectedTab, setSelectedTab] = useState("0")

    const [builders, setBuilders] = useState([])
    const [selectedBuilders, setSelectedBuilders] = useState([])

    const [didLoadData, setDidLoadData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [evenflow, setEvenflow] = useState({
        template: [],
        data: [],
    })
    const [buttonText, setButtonText] = useState('Get Evenflow')

    const [filterDate, setFilterDate] = useState(new Date())
    const [view, setView] = useState('')

    const [templates, setTemplates] = useState([])

    useEffect(() => {
        const loadData = async () => {
            if (Utilities.isLoggedOut()) {
                const uri = Config.BASE_UI();
                window.location.replace(uri);
            } else {
                setIsLoading(true)
                setDidLoadData(true);

                setBuilders(await Builders.loadBuildersSelect(null))
                setTemplates(await Schedules.loadTemplates(null))
                setIsLoading(false)
            }
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const [matchFromStart] = useState(true);
    const [ignoreCase] = useState(true);
    const [ignoreAccents] = useState(true);
    const [trim] = useState(true);

    const filterConfig = {
        ignoreCase,
        ignoreAccents,
        trim,
        matchFrom: matchFromStart ? ('start') : ('any'),
    };

    const handleBuilderChanged = async (selections) => {
        setSelectedBuilders(selections)
    }

    const handleGetEvenflowData = async() => {
        setButtonText('Fetching Report...');
        let builderIds = await Builders.getSelectedBuilderIds(selectedBuilders)
        if (builderIds.length > 0) {
            let tempEvenflow = await Evenflow.loadEvenflow(builderIds, filterDate.startOf('month'))
            setEvenflow(tempEvenflow)
        } else {
            setEvenflow({
                template: [],
                data: [],
            })
        }
        setButtonText('Get Evenflow');
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const handleDownload = () => {

        const columnWidths = []
        let loadedWidths = false

        evenflow.data.forEach((bldr) => {
            const workbook = utils.book_new();
            bldr.jobs.forEach((job) => {

             //   let jobTemplate = templates.find(x => x.id === job.base_schedule_template_id)

                let lotJson = []
                let nextMergeRow = 3
                let merges = [ {s: {r: 1, c: 0}, e: {r:  2, c: 0}}]
                job.lots.forEach((lot) => {
                    if (!loadedWidths) {
                        columnWidths.push(20)
                        columnWidths.push(20)
                        columnWidths.push(20)
                    }
                    lotJson.push({
                        lot: lot.lot,
                        start: lot.start,
                        status: '',
                    })
                    evenflow.template.forEach((item) => {
                        let calcDaysStop = item.calc_days_stop
                        let taskName = (`${item.task.code !== null ? item.task.code : ''} ${item.task.name}`).trim()
                        if (lot[taskName] !== undefined) {
                            let schedule = lot[taskName].schedule
                            lotJson[lotJson.length - 1][taskName] = schedule === null ? '' : `${schedule}`
                            lotJson[lotJson.length - 1].status = `schedule`
                        }
                        else{
                            lotJson[lotJson.length - 1][taskName] = null
                            lotJson[lotJson.length - 1].status = ``
                        }
                        if(calcDaysStop) {
                            if(lot[item.calc_days_label] !== undefined) {
                                let sDays = lot[item.calc_days_label].schedule_days
                                let sLabel = lot[item.calc_days_label].schedule
                                let aDays = lot[item.calc_days_label].actual_days === null ? ' - ' : lot[item.calc_days_label].actual_days
                                let isAvg = lot[item.calc_days_label] === undefined ? false : lot[item.calc_days_label].is_avg
                                let dDays = isAvg ? lot[item.calc_days_label].delay_days > 0 ? lot[item.calc_days_label].delay_days : '' : ''
                                lotJson[lotJson.length - 1][item.calc_days_label] = isAvg ? 'Avg' : `${sDays}`
                            }
                        }
                    })
                    lotJson.push({
                        lot: lot.lot,
                        start: lot.start,
                        status: '',
                    })
                    evenflow.template.forEach((item) => {
                        let calcDaysStop = item.calc_days_stop
                        let taskName = (`${item.task.code !== null ? item.task.code : ''} ${item.task.name}`).trim()
                        let isAvg = false
                        if (lot[taskName] !== undefined) {
                            isAvg = lot[taskName].is_avg
                            let schedule = lot[taskName].schedule
                            let average = lot[taskName].actual_days
                            let actual = lot[taskName].actual
                            lotJson[lotJson.length - 1][taskName] = actual === null ? isAvg ? '' : ' - ' : `${actual}`
                            lotJson[lotJson.length - 1].status = `actual`
                        }
                        else{
                            lotJson[lotJson.length - 1][taskName] = null
                            lotJson[lotJson.length - 1].status = ``
                        }
                        if(calcDaysStop) {
                            isAvg = false
                            if(lot[item.calc_days_label] !== undefined) {
                                let aDays = lot[item.calc_days_label].actual_days === null ? ' - ' : lot[item.calc_days_label].actual_days
                                isAvg = lot[item.calc_days_label].is_avg
                                let dDays = isAvg ? lot[item.calc_days_label].delay_days > 0 ? lot[item.calc_days_label].delay_days : '' : ''
                                lotJson[lotJson.length - 1][item.calc_days_label] = `${aDays}`
                            }
                        }
                    })
                    merges.push(
                        {s: {r: nextMergeRow, c: 0}, e: {r: nextMergeRow + 1, c: 0}},
                        {s: {r: nextMergeRow, c: 1}, e: {r: nextMergeRow + 1, c: 1}})
                    nextMergeRow += 2
                })

                const worksheet = utils.json_to_sheet(lotJson, {
                    FreezePanes: { xSplit: "3", ySplit: "1" },
                    freezepanes: { xSplit: "3", ySplit: "1" },
                    "!freeze": { xSplit: "3", ySplit: "1" },
                    sheetView: { xSplit: "3", ySplit: "1" },
                });
                worksheet['!merges'] = merges;

                const range = utils.decode_range(worksheet["!ref"] ?? "")
                const rowCount = range.e.r
                const columnCount = range.e.c

                // Add formatting by looping through data in sheet
                for (let row = 0; row <= rowCount; row++) {
                    for (let col = 0; col <= columnCount; col++) {

                        const cellRef = utils.encode_cell({r: row, c: col})
                        const statusRef = utils.encode_cell({r: row, c: 3})
                        // Add this format to every cell

                        let status = ''
                        if(statusRef !== null && worksheet[statusRef] !== undefined){
                            status = worksheet[statusRef].v
                        }
                        if(cellRef !== null && worksheet[cellRef] !== undefined) {
                            let val = worksheet[cellRef].v
                            let fillColor = 'FFFFFF'
                            if(row % 2 === 0 && row > 2 && col > 3){
                                fillColor = 'D3D3D3'
                            }
                            else {
                                if (val !== undefined && col > 2) {
                                    if (val === null) {
                                        fillColor = '808080'
                                    } else if (val.includes('late') || val > 0) {
                                        fillColor = 'FFC0CB'
                                    } else if (val.includes('early') || val < 0) {
                                        fillColor = '98FB98'
                                    } else if (val.includes('ontime') || val === 0) {
                                        fillColor = 'FCF3CF'
                                    }
                                }
                            }
                            worksheet[cellRef].s = {
                                ...worksheet[cellRef].s,
                                width: 9,
                                wch: 9,
                                fill: {fgColor: {rgb: fillColor}, patternType: 'solid'},
                                border: {
                                    top: status === 'due' ? { style: 'thin', color: { auto: 1 } } : '',
                                    right: col > 2 ? { style: 'thin', color: { auto: 1 } } : '',
                                    left: col > 2 ? { style: 'thin', color: { auto: 1 } } : '',
                                    bottom: status === 'complete' ? { style: 'thin', color: { auto: 1 } } : '',
                                },
                                alignment: {
                                    horizontal: "center",
                                    vertical: "center",
                                    wrapText: false,
                                    width: 9,
                                    wch: 9,
                                },
                            }
                        }

                        // vertical header - 1st column only
                        if (row === 0 && worksheet[cellRef] !== undefined) {
                            worksheet[cellRef].s = {
                                ...worksheet[cellRef].s,
                                wch: 9,
                                fill: { fgColor: { rgb: "D3D3D3"}, patternType: 'solid'},
                                border: {
                                    top: { style: 'thin', color: { auto: 1 } },
                                    right: { style: 'thin', color: { auto: 1 } },
                                    bottom: { style: 'thin', color: { auto: 1 } },
                                    left: { style: 'thin', color: { auto: 1 } },
                                },
                                alignment: {
                                    horizontal: "center",
                                    vertical: "center",
                                    wrapText: false,
                                    textRotation: 45,
                                    font: {bold: true},
                                },
                            }
                        }
                    }
                }

                worksheet["!cols"] = columnWidths.map(width => ({ wch: width }))
                worksheet['freezepanes'] = { xSplit: "3", ySplit: "1" };
                worksheet['!freeze'] = { xSplit: "3", ySplit: "1" };
                worksheet['sheetView'] ={ xSplit: "3", ySplit: "1" };
                const sheetName = job.job.length > 31 ? job.job.substring(0, 31) : job.job;
                utils.book_append_sheet(workbook, worksheet, sheetName);
            })

            XLSX.writeFile(workbook, `${bldr.builder} Evenflow.xlsx`);
        })
    }

    const handleFilterDateChanged = (selection) => {
        setFilterDate(selection)
    }

    return (
        <EvenflowPageContainer>
            <div className={'filter-box'}>
                <div className={'filter-builder'}>
                    <Select
                        id="builder-filter-select-id"
                        label="Select A Builder"
                        required
                        onChange={async (e) => await handleBuilderChanged(e)}
                        options={builders}
                        isClearable
                        isSearchable
                        isMulti={true}
                        value={selectedBuilders}
                        filterOption={createFilter(filterConfig)}
                        placeholder={"Select a Builder"}
                    />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker className={"filter-date"}
                                label={'Start'}
                                views={['year', 'month']}
                                onChange={(e) => handleFilterDateChanged(e)}
                                defaultValue={dayjs(filterDate)}
                                selected={filterDate}
                                onviewChange={(newView, reason) => reason!=='selection' && setView(newView)}

                    />
                </LocalizationProvider>
                <Button
                    className="filter-button"
                    variant="contained"
                    disabled={isLoading}
                    onClick={(e) => handleGetEvenflowData()}
                >
                    {buttonText}
                </Button>
            </div>
            <div className={'evenflow-data'}>
                <TabContext value={selectedTab}>
                    <TabList variant={"scrollable"} onChange={handleTabChange}>
                        {selectedBuilders.map((builder, index) => {
                            return (
                                <Tab key={`builder-tab-${index}`} className={'tab-active'} label={builder.name} value={index.toString()}/>
                            )
                        })}
                    </TabList>
                    {selectedBuilders.map((builder, index) => {
                        return (
                             <TabPanel key={`builder-panel-${index}`} value={index.toString()}>
                                <div key={`builder-panel-${index}-averages`} className={"average-table"}>
                                </div>
                                 <div className={"export-div"}>
                                     {evenflow.data.length > 0 &&
                                         <Tooltip title="Download Excel">
                                             <span>
                                                 <IconButton
                                                     color="error"
                                                     disabled={(evenflow.data.length === 0) || isLoading}
                                                     onClick={handleDownload}
                                                     variant="contained"
                                                 >
                                                     <span>Export Evenflow</span><FontAwesomeIcon icon={faFileExcel} />
                                                 </IconButton>
                                             </span>
                                         </Tooltip>}
                                 </div>
                                <EvenflowGrid
                                    selectedBuilder={builder}
                                    template={evenflow.template}
                                    evenflow={evenflow.data.find(x => x.builder_id === builder.id)}
                                />
                            </TabPanel>
                        )
                    })}
                </TabContext>
            </div>
        </EvenflowPageContainer>
    )
}

export default EvenflowPage;
