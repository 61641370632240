import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';
import Select from "react-select";

const JobAttributesAndAssignments = ({job, setJob, attributeName, attributeId, attributeOptId, 
    generalAssignmentId, superAssignmentId, generals, supers}) => {

    const assignedGeneral = job.assignments.find(a => parseInt(a.assignment_id) === generalAssignmentId) ? 
                            job.assignments.find(a => parseInt(a.assignment_id) === generalAssignmentId).user : null;
    const assignedSuper = job.assignments.find(a => parseInt(a.assignment_id) === superAssignmentId) ? 
                            job.assignments.find(a => parseInt(a.assignment_id) === superAssignmentId).user : null;

    const processHasAttribute = (id) => {
        const index = job.attributes.findIndex(a => parseInt(a.attribute_id) === id);
        if(index >= 0){
            job.attributes.splice(index, 1);
        }
        else{
            job.attributes.push({attribute_id: id});
        }
        setJob({...job});
    }

    const handleHasAttribute = (id) => {
        // IF LOT NOT SHOWING ALL PHASES OF PLUMBING IN LOTS TABLE
        // CHECK THAT JOB ATTRIBUTES HAS ALL PLUMBING ATTRIBUTES(2, 3 AND 4)
        if(parseInt(id) === 2){
            processHasAttribute(2);
            processHasAttribute(3);
            processHasAttribute(4);
            return;
        }

        processHasAttribute(id);
    }

    const handleAssignmentNewJob = (user, assignmentId) => {
        if(user === null){
            const index = job.assignments.findIndex(a => parseInt(a.assignment_id) === assignmentId);
            if(index >= 0){
                job.assignments.splice(index, 1);
            }
        }
        else{
            job.assignments.push({assignment_id: assignmentId, user_id: user.id, user: user});
        }
        setJob({...job});
    }

    return (
        <Box 
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(30, 1fr)',
                margin: '5px 0',
            }}
        >
            <Box // Attribute Name
                sx={{
                    gridColumn: '1/7',
                    alignSelf: 'center',
                    textAlign: 'right',
                }}
            >{attributeName}</Box>
            <Box // Has Attribute
                sx={{
                    gridColumn: '7/9',
                    justifySelf: 'center',
                    alignSelf: 'center',
                }}
            >
                <Checkbox 
                    checked={job.attributes.find(a => parseInt(a.attribute_id) === attributeId) !== undefined}
                    onChange={() => handleHasAttribute(attributeId)}
                />
            </Box>
            <Box // Is Attribute Optional
                sx={{
                    gridColumn: '9/11',
                    justifySelf: 'center',
                    alignSelf: 'center',
                }}
            >
                {attributeOptId !== null && attributeOptId !== undefined ?
                <Checkbox 
                    checked={job.attributes.find(a => parseInt(a.attribute_id) === attributeOptId) !== undefined}
                    onChange={() => handleHasAttribute(attributeOptId)}
                    disabled={job.attributes.find(a => parseInt(a.attribute_id) === attributeId) === undefined}
                /> : null}
            </Box>
            <Box // Attribute General Super Assignment
                sx={{
                    gridColumn: '11/21',
                    padding: '0 3px',
                }}
            >
                {generals !== null && generals !== undefined ?
                <Select 
                    styles={{
                        menu: styles => {
                            return {
                                ...styles,
                                width: '300px',
                            }
                        },
                    }} 
                    placeholder="Select General"
                    value={assignedGeneral}
                    onChange={(gen) => handleAssignmentNewJob(gen, generalAssignmentId)}
                    options={generals}
                    isClearable
                    isSearchable
                /> : null}
            </Box>
            <Box // Attribute General Super Assignment
                sx={{
                    gridColumn: '21/31',
                    padding: '0 3px',
                }}
            >
                {supers !== null && supers !== undefined ?
                <Select 
                    styles={{
                        menu: styles => {
                            return {
                                ...styles,
                                width: '300px',
                            }
                        },
                    }} 
                    placeholder="Select Super"
                    value={assignedSuper}
                    onChange={(sup) => handleAssignmentNewJob(sup, superAssignmentId)}
                    options={supers}
                    isClearable
                    isSearchable
                /> : null}
            </Box>
        </Box>
    );
}

export default JobAttributesAndAssignments;