import { useState } from 'react';
import Lots from '../../classes/Lots.js';
import Utilities from '../../classes/Utilities'
import moment from "moment";
import Auth from "../authentication/Auth.js";
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";
import Box from '@mui/material/Box';
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Can from '../authentication/Can.js';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const PlumbingCameraQAForm = (props) => {

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);
    const [didLoadData, setDidLoadData] = useState(false);

    const [showHistory, setShowHistory] = useState(-1)
    const [showAllHistory, setShowAllHistory] = useState(false)

    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState('');
    const [questions, setQuestions] = useState([]);
    const [success, setSuccess] = useState(false);

    const [historyDisplayToggle, setHistoryDisplayToggle] = useState({});

    // Check if user has permissions to edit form
    const canEditCameraQAForm = Can.isAuthorized('cameraQA', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);
        setQuestions(await Utilities.loadQuestions(1))
    }

    if (!didLoadData) {
        loadData();
    }

    const lotSelected = async (lot) => {
        // Get selected lot
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());

        let questions = {}
        let comments = []
        if(lot !== null) {
            for (let q = 0; q < tempSelectedLot.qa.length; q++) {
                if (tempSelectedLot.qa[q].qa !== undefined) {
                    questions[tempSelectedLot.qa[q].qa.name] = tempSelectedLot.qa[q].value
                    if (tempSelectedLot.qa[q].qa.name === 'comments') {
                        comments.push({
                                date: tempSelectedLot.qa[q].date,
                                user: tempSelectedLot.qa[q].user.firstname + ' ' + tempSelectedLot.qa[q].user.lastname,
                                value: tempSelectedLot.qa[q].value
                            }
                        )
                    }
                }
            }
        }
        setComments(comments)
        return tempSelectedLot
    }

    const toggleRadioButton = async (id, index) => {
        if(!canEditCameraQAForm){
            return;
        }

        if(id === null) {
            selectedLot.qa[index].value = selectedLot.qa[index].value === '0' ? '1' : '0'
        }
        else {
            let shouldAdd = true;
            for (let l = 0; l < selectedLot.qa.length; l++) {
                if (selectedLot.qa[l].id === undefined && selectedLot.qa[l].qa_id === selectedLot.qa[index].qa_id) {
                    shouldAdd = false
                    selectedLot.qa[l].user_id = await Auth.getUserId()
                    selectedLot.qa[l].date = moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                    selectedLot.qa[l].value = selectedLot.qa[index].value.toString() === '0' ? '1' : '0'
                    selectedLot.qa[l].user = {
                        id: await Auth.getUserId(),
                        firstname: JSON.parse(await Auth.getFirstName()).firstName,
                        lastname: JSON.parse(await Auth.getLastName()).lastName
                    }
                    break
                }
            }
            if (shouldAdd) {
                let newQA = {}
                newQA.field_id = selectedLot.qa[index].field_id
                newQA.lot_id =  selectedLot.id
                newQA.qa_id =  selectedLot.qa[index].qa_id
                newQA.user_id = await Auth.getUserId()
                newQA.date = moment(new Date()).format('YYYY-MM-DD H:mm:ss')
                newQA.value = selectedLot.qa[index].value.toString() === '0' ? '1' : '0'
                selectedLot.qa[selectedLot.qa.length] = newQA
            }
        }

        //Fastest way I could think of to get this updated for the front end.
       selectedLot.qa_frontend[index].value = selectedLot.qa_frontend[index].value === '0' ? '1' : '0'


        const tempHistoryDisplayToggle = selectedLot.qa.filter(lq => lq.qa_id === selectedLot.qa[index].qa.id)
        setHistoryDisplayToggle(tempHistoryDisplayToggle);
        //just doing this to stop the unused var warning.
        return historyDisplayToggle
    }

    const toggleHistoryDisplay = (id) => {
        if(showHistory > -1 && showHistory.toString() === id.toString()){
            setShowHistory(-1)
        }
        else {
            setShowHistory(id)
        }
    }

    const handleShowAllHistory = () => {
        if(showAllHistory){
            setShowAllHistory(false)
        }
        else {
            setShowAllHistory(true)
        }
    }

    const handleSubmitCameraQAForm = async (e) => {

        let lot = {...selectedLot}
        if(!canEditCameraQAForm){
            return;
        }

        if(newComment.length > 0) {
            let date = new Date()
            let newQA = {}
            newQA.field_id = lot.qa[0].field_id
            newQA.lot_id = lot.id
            newQA.qa_id = questions.find(q => q.field_id.toString() === lot.qa[0].field_id.toString() && q.name === 'comments').id
            newQA.user_id = await Auth.getUserId()
            newQA.date = moment(date).format('YYYY-MM-DD H:mm:ss')
            newQA.value = newComment
            lot.qa[lot.qa.length] = newQA

            lot.qa_frontend[lot.qa_frontend.length] = newQA
            lot.qa_history[lot.qa_history.length - 1].comments.splice(0, 0, newQA)
            comments.splice(0, 0,{
                    date: date,
                    user: await Auth.getFullName(),
                    value: newComment
                }
            )
            setComments(comments)
        }

        delete lot.gate_information
        delete lot.value
        delete lot.label
        delete lot.lot

        const updatedLot = await Lots.updateLot(lot, null);

        //Update lots list
        let tempLots = lots;
        const selectedLotIndex = lots.findIndex(lot => lot.id.toString() === selectedLot.id.toString());
        tempLots[selectedLotIndex] = updatedLot[0];
        setLots(tempLots);
        setNewComment('');
        setSuccess(true);
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <JobLotFilter
                title={'Plumbing Camera - QA Form'}
                jobs={jobs}
                setJobs={setJobs}
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                lots={lots}
                setLots={setLots}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
                lotSelected={lotSelected}
            />
            {selectedLot &&
            <Box
                sx={{
                    background: 'rgb(210, 200, 190)',
                    padding: '0 0 15px',
                    margin: '3px 0 100px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderBottom: '3px solid white'
                    }}
                >Camera - QA Form</Box>
                <Box
                    sx={{
                        display: 'grid',
                        margin: '10px 0'
                    }}
                >
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center'
                        }}
                        control={<Switch 
                            checked={showAllHistory} 
                            onChange={handleShowAllHistory}/>} 
                        label="Show All History" 
                    />
                </Box>
                {questions.map((q, i) => {
                    const result = selectedLot.qa_frontend.find(r => r.qa_id.toString() === q.id.toString())
                    const history = selectedLot.qa_history.filter(h => h[q.name] !== undefined)
                    const index = selectedLot.qa_frontend.indexOf(result)
                    return (
                        selectedLot.qa_frontend[index] && q.name !== 'comments' &&
                        <Box
                            key={q.id}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(20, 1fr)',
                                margin: '10px 0',
                            }}
                        >
                            <Box
                                sx={{
                                    gridColumn: '1/3',
                                    textAlign: 'end',
                                    paddingRight: '3px',
                                }}
                            >
                                {q.id}.
                            </Box>
                            <Box
                                sx={{
                                    gridColumn: '3/21'
                                }}
                            >
                                {q.display}
                            </Box>
                            <RadioGroup
                                sx={{
                                    gridColumn: '3/12',
                                }}
                                row
                                value={parseInt(selectedLot.qa_frontend[index].value) === 1 ? 'yes' : 'no'}
                                onChange={() => toggleRadioButton(result.qa_id, index)}
                            >
                                <FormControlLabel value="yes" control={<Radio size='small'/>} label="Yes" />
                                <FormControlLabel value="no" control={<Radio  size='small'/>} label="No" />
                            </RadioGroup>
                            <Box
                                sx={{
                                    gridColumn: '12/20',
                                    display: 'grid',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                }}
                            >
                                <Tooltip title="Toggle Question History">
                                    <HistoryIcon 
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => toggleHistoryDisplay(result.qa_id)}
                                    />
                                </Tooltip>
                            </Box>
                            {(parseInt(showHistory) === parseInt(result.qa_id) || showAllHistory) &&
                            <Box
                                sx={{
                                    gridColumn: '1/21'
                                }}
                            >
                                {history && history[0] && history[0][q.name] ?
                                history[0][q.name].map((h, hIndex) => {
                                    return (
                                        <Box 
                                            key={hIndex}
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: 'repeat(20, 1fr)',
                                                margin: '3px 0',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    gridColumn: '3/8'
                                                }}
                                            >
                                                {moment(new Date(h.date)).format('MM-DD-YYYY')}
                                            </Box>
                                            <Box
                                                sx={{
                                                    gridColumn: '8/17'
                                                }}
                                            >
                                                {h.user.firstname} {h.user.lastname}
                                            </Box>
                                            <Box
                                                sx={{
                                                    gridColumn: '17/19'
                                                }}
                                            >
                                                {parseInt(h.value) === 1 ? 'Yes' : 'No'}
                                            </Box>
                                        </Box>
                                    )
                                }) : 
                                <Box>
                                    No History To Display
                                </Box>}
                            </Box>}
                        </Box>
                    )
                })}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(20, 1fr)',
                    }}
                >
                    <Box
                        sx={{
                            gridColumn: '2/21'
                        }}
                    >
                        Comments:
                    </Box>
                    {comments.length > 0 &&
                    comments.map((c, cIndex) => {
                        return (
                            <Box
                                key={cIndex}
                                sx={{
                                    gridColumn: '1/21',
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(20, 1fr)',
                                    margin: '5px 0',
                                }}
                            >
                                <Box
                                    sx={{
                                        gridColumn: '3/7',
                                        marginBottom: '3px'
                                    }}
                                >
                                    {moment(new Date(c.date)).format('MM-DD-YYYY')}
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: '8/20',
                                        marginBottom: '3px'
                                    }}
                                >
                                    {c.user}
                                </Box>
                                <Box
                                    sx={{
                                        gridColumn: '3/20'
                                    }}
                                >
                                    {c.value}
                                </Box>
                            </Box>
                        )
                    })}
                    {canEditCameraQAForm &&
                    <Box
                        sx={{
                            gridColumn: '2/20',
                            display: 'grid',
                            margin: '10px 0',
                        }}
                    >
                        <TextField
                            sx={{background: 'white'}}
                            size='small'
                            placeholder='Enter New Comment'
                            multiline
                            rows='4'
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                    </Box>}
                </Box>
                {canEditCameraQAForm && 
                <Box
                    sx={{
                        gridColumn: '2/20',
                        justifySelf: 'center',
                        margin: '10px 0'
                    }}
                >
                    <Button variant='contained' onClick={handleSubmitCameraQAForm}>Submit Camera QA Form</Button>
                </Box>}
            </Box>}
            {success &&
            <Dialog open={success}>
                <DialogTitle sx={{backgroundColor: 'rgb(150, 255, 150)', fontWeight: 'bold'}}>Success</DialogTitle>
                <DialogContent 
                    sx={{width: "350px", backgroundColor: 'rgb(150, 255, 150)'}}>Camera QA Form saved successfully</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(150, 255, 150)'}}>
                    <Button 
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => setSuccess(false)}
                    >OK</Button>
                </DialogActions>
            </Dialog>}
        </FieldFormsContainer>
    )
}

export default PlumbingCameraQAForm;