import { useState } from 'react';
import dayjs from "dayjs";
import LotNotes from '../lots/LotNotes.js';
import Auth from '../authentication/Auth.js';
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Can from '../authentication/Can.js';
import TextField from "@mui/material/TextField";
import Select from "react-select";
import Checkbox from '@mui/material/Checkbox';

const GateInformation = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [measurementTypes, setMeasurementTypes] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null)
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);

    const [error, setError] = useState('');

    const canCreateEditGatesInformation = Can.isAuthorized('gateInformation', 'WRITE');

    const loadData = async () => {
        setDidLoadData(true);

        const tempMeasurementTypes = await Auth.hitEndpointNew("GET", "measurement-types")
        setMeasurementTypes(tempMeasurementTypes.map(m => {
            return {
                ...m,
                value: m.id,
                label: m.name
            }
        }));
    }

    if (!didLoadData) {
        loadData();
    }

    const setupLotGateInformation = (lot) => {
        if(lot !== null) {
            lot.gatesPhase = lot.phases.find(p => parseInt(p.phase_id) === 9)
            if (lot.gatesPhase === undefined) {
                lot.gatesPhase = {
                    release_date: null,
                    starts_status: null
                }
            }
            lot.gate_information = lot.gate_information.map(g => {
                return {
                    ...g,
                    displayMode: 'display',
                    measurement_type: g.measurement_type ? {
                        ...g.measurement_type,
                        value: g.measurement_type.id,
                        label: g.measurement_type.name
                    } : null,
                    handedness: g.handedness ? {
                        value: g.handedness,
                        label: g.handedness
                    } : null,
                    decorative_iron: g.decorative_iron ? true : false,
                    level: g.level ? {value: g.level, label: g.level} : null,
                }
            })
        }
        return lot;
    }

    const lotSelected = async (lot) => {
        const tempLot = lot === null ? null : (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${lot.id}`))[0];
        tempLot.value = lot.value;
        tempLot.label = lot.label;
        tempLot.lot = lot.lot;
        return setupLotGateInformation(tempLot);
    }

    const handleSaveGateInformation = async (gateId) => {
        const index = gateId !== undefined ? selectedLot.gate_information.findIndex(g => parseInt(g.id) === parseInt(gateId)) : 0;
        if(selectedLot.gate_information[index].width === ''){
            setError('NO_WIDTH')
            return;
        }
        if(selectedLot.gate_information[index].measurement_type === null){
            setError('NO_MEASUREMENT_TYPE')
            return;
        }
        if(selectedLot.gate_information[index].handedness === null){
            setError('NO_HANDEDNESS')
            return;
        }

        const dbObj = {
            id: selectedLot.id,
            gate_information: selectedLot.gate_information.map(g => {
                delete g.measurement_type;
                delete g.displayMode;
                delete g.user;
                g.handedness = g.handedness.label;
                g.level = g.level ? g.level.label : null;
                g.created_at = dayjs(new Date(g.created_at)).format('YYYY-MM-DD');
                g.level = g.level ? g.level : null;
                return g
            })
        }
        await Auth.hitEndpointNew("PATCH", `lot/no-processing`, '', dbObj);

        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${selectedLot.id}`))[0];
        tempLot.value = selectedLot.value;
        tempLot.label = selectedLot.label;
        tempLot.lot = selectedLot.lot;
        setSelectedLot(setupLotGateInformation(tempLot));
        setError('');
    }

    const handleCancelCreateEditGateInformation = async () => {
        const tempLot = (await Auth.hitEndpointNew("GET", "lots/index-new", `queryString=lot.id=${selectedLot.id}`))[0];
        tempLot.value = selectedLot.value;
        tempLot.label = selectedLot.label;
        tempLot.lot = selectedLot.lot;
        setSelectedLot(setupLotGateInformation(tempLot));
        setError('');
    }

    const handleAddGateInformation = async () => {
        const tempLot = {...selectedLot};
        tempLot.gate_information.splice(0, 0, {
            displayMode: 'create',
            lot_id: '',
            width: '',
            deleted_at: null,
            measurement_type: null,
            measurement_type_id: null,
            handedness: null,
            decorative_iron: false,
            level: null,
            created_by: await Auth.getUserId(),
            created_at: new Date()
        });
        setSelectedLot({...tempLot});
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
    } 

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <Box
                sx={{
                    marginBottom: '100px'
                }}
            >
                <JobLotFilter
                    title={'Gate Information'}
                    jobs={jobs}
                    setJobs={setJobs}
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    lots={lots}
                    setLots={setLots}
                    selectedLot={selectedLot}
                    setSelectedLot={setSelectedLot}
                    lotSelected={lotSelected}
                />
                {selectedLot !== null && 
                <Box
                    sx={{
                        background: 'rgb(210, 200, 190)',
                        padding: '0 0 15px',
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '18px',
                            color: 'white',
                            textAlign: 'center',
                            background: 'rgb(128, 128, 128)',
                            padding: '3px',
                            borderTop: '3px solid white',
                            borderBottom: '3px solid white'
                        }}
                    >Gate Information</Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'end',
                                paddingRight: '5px'
                            }}
                        >Release Date:</Box>
                        {selectedLot.gatesPhase.release_date ?
                        <Box>{dayjs(new Date(selectedLot.gatesPhase.release_date)).format('YYYY-MM-DD')}</Box>:
                        <Box>Not Released</Box>}
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'end',
                                paddingRight: '5px'
                            }}
                        >Starts Status:</Box>
                        {selectedLot.gatesPhase.starts_status ?
                        <Box>{selectedLot.gatesPhase.starts_status}</Box>:
                        <Box>N/A</Box>}
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                alignSelf: 'center',
                                textAlign: 'end',
                                paddingRight: '5px'
                            }}
                        >Lot Notes:</Box>
                        <Tooltip title="View/Edit Notes">
                            <TextSnippetIcon 
                                sx={{cursor: 'pointer'}}
                                onClick={() => setDisplayNotes(true)}
                            />
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'end',
                                paddingRight: '5px'
                            }}
                        >Number Of Gates:</Box>
                        <Box>{selectedLot.gate_information ? selectedLot.gate_information.length : '0'}</Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            margin: '20px 0'
                        }}
                    >
                        <Button variant='contained' onClick={handleAddGateInformation}>Add Gate</Button>
                    </Box>
                </Box>}
                {selectedLot !== null && selectedLot.gate_information !== null && 
                selectedLot.gate_information.filter(g => g.deleted_at === null).map((g, index) => {
                    if((g.displayMode === 'edit' || g.displayMode === 'create') && canCreateEditGatesInformation){
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    gridTemplateColumns: 'repeat(20, 1fr)',
                                    padding: '10px 0',
                                    backgroundColor: 'rgb(210, 200, 190)',
                                    borderTop: '2px solid black',
                                }}
                            >
                                <Box
                                    sx={{
                                        gridColumn: '1/21',
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        margin: '5px 0 10px'
                                    }}
                                >{'Gate ' + (index + 1)}</Box>
                                <Box
                                    sx={{
                                        gridColumn: '1/8',
                                        textAlign: 'end',
                                        paddingRight: '5px',
                                        margin: '20px 0'
                                    }}
                                >Width:</Box>
                                <TextField
                                    sx={{
                                        gridColumn: '8/11',
                                        backgroundColor: error === "NO_WIDTH" ? 'rgb(255, 150, 150)' : 'white',
                                    }}
                                    size='small'
                                    value={g.width}
                                    onChange={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].width = e.target.value;
                                        setSelectedLot({...tempLot});
                                    }}
                                />
                                <Select 
                                    styles={{
                                        control: styles => {
                                            return {
                                                ...styles,
                                                backgroundColor: error === "NO_MEASUREMENT_TYPE" ? 'rgb(255, 150, 150)' : 'white',
                                            }
                                        },
                                        container: styles => {
                                            return {
                                                ...styles,
                                                gridColumn: '12/20',
                                                zIndex: '49'
                                            }
                                        }
                                    }} 
                                    value={g.measurement_type}
                                    onChange={(measurementType) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].measurement_type_id = measurementType ? measurementType.value : null;
                                        tempLot.gate_information[index].measurement_type = measurementType
                                        setSelectedLot({...tempLot});
                                    }}
                                    options={measurementTypes}
                                />
                                <Box
                                    sx={{
                                        gridColumn: '1/8',
                                        textAlign: 'end',
                                        paddingRight: '5px',
                                        margin: '20px 0'
                                    }}
                                >Left/Right:</Box>
                                <Select 
                                    styles={{
                                        control: styles => {
                                            return {
                                                ...styles,
                                                backgroundColor: error === "NO_HANDEDNESS" ? 'rgb(255, 150, 150)' : 'white',
                                            }
                                        },
                                        container: styles => {
                                            return {
                                                ...styles,
                                                gridColumn: '8/16',
                                                zIndex: '48'
                                            }
                                        }
                                    }} 
                                    value={g.handedness}
                                    onChange={(handedness) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].handedness = handedness;
                                        setSelectedLot({...tempLot});
                                    }}
                                    options={[{value: 'Left', label: 'Left'}, {value: 'Right', label: 'Right'}, {value: 'Double', label: 'Double'}]}
                                />
                                <Box
                                    sx={{
                                        gridColumn: '1/8',
                                        textAlign: 'end',
                                        paddingRight: '5px',
                                        margin: '5px 0'
                                    }}
                                >Decorative Iron:</Box>
                                <Checkbox  
                                    checked={g.decorative_iron}
                                    onChange={(e) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].decorative_iron = e.target.checked;
                                        setSelectedLot({...tempLot});
                                    }}
                                />
                                <Box
                                    sx={{
                                        gridColumn: '1/8',
                                        textAlign: 'end',
                                        paddingRight: '5px',
                                        margin: '20px 0'
                                    }}
                                >Gate Level:</Box>
                                <Select 
                                    styles={{
                                        control: styles => {
                                            return {
                                                ...styles,
                                                // backgroundColor: error === "NO JOB SELECTED" ? 'rgb(255, 150, 150)' : 'white',
                                            }
                                        },
                                        container: styles => {
                                            return {
                                                ...styles,
                                                gridColumn: '8/16',
                                                zIndex: '47'
                                            }
                                        }
                                    }} 
                                    value={g.level}
                                    onChange={(level) => {
                                        const tempLot = {...selectedLot};
                                        const index = g.id !== undefined ? tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id)) : 0;
                                        tempLot.gate_information[index].level = level;
                                        setSelectedLot({...tempLot});
                                    }}
                                    options={[
                                        {value: '1', label: '1'}, 
                                        {value: '2', label: '2'}, 
                                        {value: '3', label: '3'}, 
                                        {value: '4', label: '4'}, 
                                        {value: '5', label: '5'}]}
                                    isClearable
                                />
                                <Button 
                                    sx={{
                                        gridColumn: '9/14',
                                        margin: '10px 0'
                                    }}
                                    variant='contained' 
                                    onClick={async () => {
                                        await handleCancelCreateEditGateInformation()
                                    }}
                                >Cancel</Button>
                                <Button
                                    sx={{
                                        gridColumn: '15/20',
                                    }}
                                    variant='contained' 
                                    onClick={async () => {
                                        await handleSaveGateInformation(g.id)
                                    }}
                                >Save</Button>
                            </Box>
                        )
                    }
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                padding: '10px 0',
                                backgroundColor: 'rgb(210, 200, 190)',
                                borderTop: '2px solid black',
                            }}
                        >
                            <Box
                                sx={{
                                    gridColumn: '1/3',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {'Gate ' + (index + 1)}
                                <Tooltip title={'Edit Gate ' + (index + 1)}>
                                    <IconButton onClick={() => {
                                        const tempLot = {...selectedLot};
                                        const index = tempLot.gate_information.findIndex(gate => parseInt(gate.id) === parseInt(g.id));
                                        tempLot.gate_information[index].displayMode = 'edit';
                                        setSelectedLot({...tempLot});
                                    }}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                    textAlign: 'end',
                                    paddingRight: '5px',
                                    margin: '5px 0'
                                }}
                            >Width:</Box>
                            <Box
                                sx={{
                                    margin: '5px 0'
                                }}
                            >{`${g.width} ${g.measurement_type ? g.measurement_type.name : ''}`}</Box>
                            <Box
                                sx={{
                                    textAlign: 'end',
                                    paddingRight: '5px',
                                    margin: '5px 0'
                                }}
                            >Left/Right:</Box>
                            <Box
                                sx={{
                                    margin: '5px 0'
                                }}
                            >{g.handedness ? g.handedness.label : null}</Box>
                            <Box
                                sx={{
                                    textAlign: 'end',
                                    paddingRight: '5px',
                                    margin: '5px 0'
                                }}
                            >Decorative Iron:</Box>
                            <Box
                                sx={{
                                    margin: '5px 0'
                                }}
                            >{g.decorative_iron ? 'Yes' : 'No'}</Box>
                            <Box
                                sx={{
                                    textAlign: 'end',
                                    paddingRight: '5px',
                                    margin: '5px 0'
                                }}
                            >Gate Level:</Box>
                            <Box
                                sx={{
                                    margin: '5px 0'
                                }}
                            >{g.level ? g.level.label : null}</Box>
                        </Box>
                    )
                })}
                {displayNotes &&
                <LotNotes
                    openLotNotes={displayNotes}
                    setOpenLotNotes={setDisplayNotes}
                    lotToViewNotes={selectedLot}
                    setLotToViewNotes={() => {}}
                    noteType={'Gates'}
                    updatedLotReturned={closeNotes}
                />}
            </Box>
        </FieldFormsContainer>
    )
}

export default GateInformation;