import { useMemo, useState} from 'react';
import dayjs from "dayjs";
import Auth from '../authentication/Auth.js';
import {MaterialReactTable} from "material-react-table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button  from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { Switch } from '@mui/material';
import Select from "react-select";
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const EWABillingLog = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        job: null,
        lot: null,
        scheduledDate: null,
        completedDate: null,
        qbInvoiced: false
    });

    const [cursor, setCursor] = useState('default');
    const [disableFilterButton, setDisableFilterButton] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [ewaBillingLogs, setEWABillingLogs] = useState([]);
    const [editEWABillingLog, setEditEWABillingLog] = useState(null);
    const [qbInvoiceNumber, setQBInvoiceNumber] = useState(''); // TODO does it need new data member and clearify that the other 2 data memebers is correct

    const canEditInvoice = props.isAuthorized('ewaBillingLog', 'WRITE');

    const columns = useMemo(
        () => [
            {
                id: 'job_number',
                accessorKey: 'lot.job.number',
                header: 'Job #',
                size: 150
            },
            {
                id: 'lot_number',
                accessorKey: 'lot.number',
                header: 'Lot #',
                size: 200
            },
            {
                id: 'phase_task',
                accessorFn: (row) => `${row.task.code} ${row.task.name}`,
                header: 'Phase Task',
                size: 200
            },
            {
                accessorKey: 'lot.job.builder.name',
                header: 'Builder',
                size: 250
            },
            {
                accessorKey: 'lot.job.project_name',
                header: 'Project Name',
                size: 300
            },
            {
                accessorFn: (row) => row.schedule_date ? dayjs(row.schedule_date).format('MM-DD-YYYY') : null,
                header: 'Scheduled',
                size: 200
            },
            {
                accessorFn: (row) => row.completed_date ? dayjs(row.completed_date).format('MM-DD-YYYY') : null,
                header: 'Completed',
                size: 200
            },
            {
                accessorKey: 'internal_estimate_number',
                header: 'Int Est #',
                size: 250
            },
            {
                accessorKey: 'builder_po_number',
                header: 'Builder PO',
                size: 200
            },
            {
                id: 'qb_estimate_number',
                accessorKey: 'qb_estimate_number',
                header: 'QB Est #',
                size: 250
            },
        ],
        [],
    );

    const loadData = async () => {
        setDidLoadData(true);

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const handleJobSelect = async (job) => {
        if(job === null){
            setFilters({...filters, job: null, lot: null});
            return;
        }

        setDisableFilterButton(true);
        const tempLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=lot.job_id=${job.id}`);
        setLots(tempLots.map(l => {
            return {
                ...l,
                value: l.id,
                label: `${l.number} - ${l.phase} - ${l.address}`
            }
        }));
        setFilters({...filters, job: job});
        setDisableFilterButton(false);
    }

    const handleUpdateTable = async () => {
        // TODO Does it need to be completed
        let queryStr = 'jsonString=phase_task.billable=1 and schedule.complete=1';
        if(filters.job !== null){
            queryStr += ` and lot.job_id = ${filters.job.id}`
        }
        if(filters.lot !== null){
            queryStr += ` and lot.id = ${filters.lot.id}`
        }
        // TODO Does it need date range
        // if(filters.scheduledDate != null){
        //     const dayMinusOne = dayjs(filters.scheduledDate).add(-1, 'day').format('YYYY-MM-DD');
        //     const dayPlusOne = dayjs(filters.scheduledDate).add(1, 'day').format('YYYY-MM-DD');
        //     queryStr += ` and schedule.schedule_date > '${dayMinusOne}' and schedule.schedule_date < '${dayPlusOne}'`
        // }
        // if(filters.completedDate != null){
        //     const dayMinusOne = dayjs(filters.completedDate).add(-1, 'day').format('YYYY-MM-DD');
        //     const dayPlusOne = dayjs(filters.completedDate).add(1, 'day').format('YYYY-MM-DD');
        //     queryStr += ` and schedule.completed_date > '${dayMinusOne}' and schedule.completed_date < '${dayPlusOne}'`
        // }

        const dbData = (await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, queryStr)).filter(d => {
            if(!filters.qbInvoiced){
                return !d.qb_estimate_number;
            }
            return true;
        });
        console.log(dbData);
        setEWABillingLogs(dbData);
    }

    const handleCloseWithSave = async () => {
        // Create schedule change log
        const changeLog = {
            schedule_id: editEWABillingLog.id,
            action: 'changed Quickbooks EWA billing invoice number',
            original_value: editEWABillingLog.qb_estimate_number,
            new_value: qbInvoiceNumber,
            action_date: dayjs(new Date()).format('YYYY-MM-DD'),
            action_by: await Auth.getUserId()
        }
        await Auth.hitEndpointNew('POST', 'schedule/log', '', changeLog);

        // Update EWA Billing Log table
        const tempEWABillingLog = [...ewaBillingLogs];
        const index = tempEWABillingLog.findIndex(bl => parseInt(bl.id) === parseInt(editEWABillingLog.id));
        if(index !== -1){
            tempEWABillingLog[index].qb_estimate_number = qbInvoiceNumber;
            setEWABillingLogs([...tempEWABillingLog]);
        }

        // Save change to database
        const dbObj = {
            id: editEWABillingLog.id,
            qb_estimate_number: qbInvoiceNumber
        }
        // TODO check variable names and database members to make sure everything is correct
        // await Auth.hitEndpointNew("PATCH", "schedule", "", dbObj);

        setEditEWABillingLog(null);
        setQBInvoiceNumber('');
    }

    const handleCloseNoSave = () => {
        setEditEWABillingLog(null);
        setQBInvoiceNumber('');
    }

    return (
        <Box
            sx={{
                display: 'grid',
                margin: '10px 0 0 10px'
            }}
        >
            <Box 
                sx={{ 
                    display: 'grid',
                    width: '400px',
                    border: '2px solid rgb(220, 220, 220)',
                    borderRadius: '3px',
                    marginBottom: '10px'
                }}
            >
                <Box sx={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    padding: '8px 0'}}
                >EWA Billing Log Filters</Box>
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '48'
                            }
                        },
                    }}
                    placeholder="Select Job"
                    value={filters.job}
                    onChange={handleJobSelect}
                    options={jobs}
                    isClearable
                    isSearchable
                />
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '47'
                            }
                        },
                    }}
                    placeholder="Select Lot"
                    value={filters.lot}
                    onChange={(lot) => setFilters({...filters, lot: lot})}
                    options={lots}
                    isClearable
                    isSearchable
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, scheduledDate: null});
                                return;
                            }
                            setFilters({...filters, scheduledDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Scheduled Date"
                        selected={filters.scheduledDate}
                    />
                </LocalizationProvider> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, completedDate: null});
                                return;
                            }
                            setFilters({...filters, completedDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Completed Date"
                        selected={filters.completedDate}
                    />
                </LocalizationProvider> */}
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.builder_invoiced} 
                        onChange={(e) => setFilters({...filters, builder_invoiced: e.target.checked})}/>} 
                    label="Quickbooks Invoiced" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    disabled={disableFilterButton}
                    variant='contained'
                    onClick={handleUpdateTable}
                >Update Table</Button>
            </Box>
            <MaterialReactTable
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                columns={columns}
                data={ewaBillingLogs}
                enableSorting
                layoutMode="grid-no-grow"
                enableStickyHeader
                muiTableBodyCellProps={({ row, cell }) => ({
                    sx: {
                        cursor: canEditInvoice ? cursor : 'default',
                    },
                    onMouseOver: () => {
                        if(cell.column.id === 'qb_estimate_number'){
                            setCursor('pointer');
                        }
                        else{
                            setCursor('default');
                        }
                    },
                    onClick: () => {
                        if(!canEditInvoice){
                            return;
                        }

                        setEditEWABillingLog(row.original);
                    }
                })}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {left: ['job_number', 'lot_number', 'phase_task']}
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Box 
                            sx={{
                                display: 'flex',
                                width: "100%",
                                justifyContent: "center",
                                fontSize: "30px",
                                fontWeight: "bold",
                                cursor: "default"
                            }}
                        >
                            EWA Billing Log
                        </Box>
                    );
                }}
            />
            {editEWABillingLog !== null && 
            <Dialog open={editEWABillingLog !== null}>
                <DialogTitle style={{borderBottom: "2px solid black"}}>EWA Billing Quickbooks Invoice</DialogTitle>
                <InfoBox label='Job:' value={editEWABillingLog.lot.job.number + " - " + editEWABillingLog.lot.job.project_name}/>
                <InfoBox label='Lot:' value={editEWABillingLog.lot.number}/>
                <InfoBox label='Phase Task:' value={editEWABillingLog.task.code + " - " + editEWABillingLog.task.name}/>
                <InfoBox label='Int Est #:' value={editEWABillingLog.internal_estimate_number}/>
                <InfoBox label='Builder PO:' value={editEWABillingLog.builder_po_number}/>
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                        }}
                        placeholder="Enter Quickbooks Est #"
                        value={qbInvoiceNumber}
                        onChange={(e) => setQBInvoiceNumber(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWithSave}>Save</Button>
                    <Button variant="contained" onClick={handleCloseNoSave}>Cancel</Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
}

const InfoBox = ({label, value}) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(10, 1fr)',
                margin: '10px auto'
            }}
        >
            <Box
                sx={{
                    gridColumn: '1/6',
                    display: 'grid',
                    alignContent: 'center',
                    textAlign: 'end',
                    padding: '5px',
                }}
            >{label}</Box>
            <Box
                sx={{
                    gridColumn: '6/10',
                    display: 'grid',
                    alignContent: 'center',
                }}
            >{value}</Box>
        </Box>
    )
}

export default EWABillingLog;