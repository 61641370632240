import {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import Auth from '../authentication/Auth.js';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { MaterialReactTable } from "material-react-table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';
import Template from './Template.js';

const ScheduleTemplate = () => {
    const [didLoadData, setDidLoadData] = useState(false);
    const [needToUpdateTable, setNeedToUpdateTable] = useState(false);

    const [filters, setFilters] = useState({
        includeDeleted: false,
    });

    const [phases, setPhases] = useState([]);
    const [phaseTasks, setPhaseTasks] = useState([]);

    const [users, setUsers] = useState([]);

    const [scheduleTemplates, setScheduleTemplates] = useState([]);

    const [scheduleTemplate, setScheduleTemplate] = useState(null);
    const [createScheduleTemplate, setCreateScheduleTemplate] = useState(false);
    const [editScheduleTemplate, setEditScheduleTemplate] = useState(false);
    const [deleteScheduleTemplate, setDeleteScheduleTemplate] = useState(false);
    const [confirmDeleteScheduleTemplate, setConfirmDeleteScheduleTemplate] = useState(false);

    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    });

    useEffect(() => {
        const loadData = async () => {
            setDidLoadData(true);

            const tempPhases = await Auth.hitEndpointNew("GET", "phases");
            setPhases(tempPhases.map(p => {
                return {
                    ...p,
                    value: p.id,
                    label: p.stat_name
                }
            }));

            const tempPhaseTasks = await Auth.hitEndpointNew("GET", "phase-tasks", `jsonString=${JSON.stringify({type: "work"})}`);
            setPhaseTasks(tempPhaseTasks.map(pt => {
                return {
                    ...pt,
                    value: pt.id,
                    label: pt.code + " " + pt.name
                }
            }));


            const tempUsers = await Auth.hitEndpointNew("GET", "users");
            setUsers(tempUsers);

            const tempScheduleTemplates = await Auth.hitEndpointNew("GET", "schedule-templates");
            for (let i = 0; i < tempScheduleTemplates.length; i++) {
                tempScheduleTemplates[i].config.sort((c1, c2) => parseInt(c1.order_by) < parseInt(c2.order_by) ? -1 : 1);
            }
            if (filters.includeDeleted) {
                setScheduleTemplates(tempScheduleTemplates);
            } else {
                setScheduleTemplates(tempScheduleTemplates.filter(st => st.active));
            }
        }

        if (!didLoadData) {
            loadData();
        }
    }, [])

    const columns = useMemo(() => {
        const getUserName = (id) => {
            const user = users.find(user => parseInt(user.id) === parseInt(id));
            return user ? `${user.firstname} ${user.lastname}` : '';
        }
    
        return [
            {
                id: 'name',
                accessorKey: 'name',
                header: 'Name',
                enableEditing: false,
                size: 200,
            },
            {
                id: 'description',
                accessorKey: 'description',
                header: 'Description',
                enableEditing: false,
                size: 300,
            },
            {
                id: 'created_by',
                accessorFn: (row) => getUserName(row.created_by),
                header: 'Created By',
                enableEditing: false,
                size: 200,
            },
            {
                id: 'created_at',
                accessorFn: (row) => dayjs(row.created_at).format('MM/DD/YYYY'),
                header: 'Created',
                enableEditing: false,
                size: 200,
            },
            {
                id: 'modified_by',
                accessorFn: (row) => getUserName(row.modified_by),
                header: 'Modified By',
                enableEditing: false,
                size: 200,
            },
            {
                id: 'last_modified',
                accessorFn: (row) => dayjs(row.modified_at).format('MM/DD/YYYY'),
                header: 'Last Modified',
                enableEditing: false,
                size: 200,
            },
            {
                id: 'total_days',
                accessorKey: 'total_days',
                header: 'Total Days',
                enableEditing: false,
                size: 200,
            }
        ]
    }, [users]);

    const updateTable = async () => {
        setNeedToUpdateTable(false);

        const tempScheduleTemplates = await Auth.hitEndpointNew("GET", "schedule-templates");
        for(let i = 0; i < tempScheduleTemplates.length; i++){
            tempScheduleTemplates[i].config.sort((c1, c2) => parseInt(c1.order_by) < parseInt(c2.order_by) ? -1 : 1);
        }
        if(filters.includeDeleted){
            setScheduleTemplates(tempScheduleTemplates);
        }
        else{
            setScheduleTemplates(tempScheduleTemplates.filter(st => st.active));
        }
    }

    if(needToUpdateTable){
        updateTable();
    }

    const closeWithDelete = async () => {
        const dbObj = {
            id: scheduleTemplate.id,
            active: false
        }

        await Auth.hitEndpointNew("POST", "schedule-template", "", dbObj);

        await updateTable();
        
        setScheduleTemplate(null);
        setDeleteScheduleTemplate(false);
        setConfirmDeleteScheduleTemplate(false);
    }

    const closeNoDelete = () => {
        setScheduleTemplate(null);
        setDeleteScheduleTemplate(false);
        setConfirmDeleteScheduleTemplate(false);
    }

    return (
        <Box sx={{
            margin: '10px',
            width: '1630px'
        }}>
            <Grid 
                sx={{
                    display: 'grid',
                    justifyContent: 'center',
                    width: 600, 
                    padding: '10px 0',
                    marginBottom: '10px',
                    border: '2px solid rgb(220, 220, 220)',
                    borderRadius: '5px',
                    zIndex: '49'
                }}
            >
                <FormControlLabel 
                    control={<Switch 
                        checked={filters.includeDeleted} 
                        onChange={async (e) => {
                            setFilters({...filters, includeDeleted: e.target.checked})
                            setNeedToUpdateTable(true);
                        }}/>} 
                    label="Included Deleted Templates" 
                />
            </Grid>
            <MaterialReactTable
                muiTableContainerProps={{ sx: { maxWidth: '1630px', maxHeight: '600px' } }}
                columns={columns}
                data={scheduleTemplates}
                layoutMode='grid-no-grow'
                enableColumnFilters
                enableStickyHeader
                enableColumnPinning
                paginationDisplayMode={'pages'}
                onPaginationChange={(paginationState) => {
                    if(!bypassPagination){
                        setPagination(paginationState);
                    }
                    setBypassPagination(false);
                }}
                initialState={{
                    pagination: pagination
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        size: 130,
                        grow: false,
                    },
                }}
                muiTableBodyCellProps={({ row }) => {
                    return {
                        sx: {
                            backgroundColor: row.original.active === 1 ? 'white' : 'rgb(255, 150, 150)'
                        }
                    }
                }}
                positionToolbarAlertBanner='hide'
                state={{pagination}}
                enableRowActions={true}
                renderRowActions={({ row }) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '1rem',}}>
                            <Tooltip title="Edit">
                                <IconButton onClick={async () => {
                                    setScheduleTemplate(row.original);
                                    setEditScheduleTemplate(true);
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => {
                                    setScheduleTemplate(row.original);
                                    setDeleteScheduleTemplate(true);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )
                }}
                renderTopToolbarCustomActions={() => {
                    return (
                        <Grid>
                            <Tooltip title="Create New Template">
                                <IconButton onClick={async () => {
                                    setScheduleTemplate({
                                        active: true,
                                        config: [],
                                        created_at: new Date(),
                                        created_by: await Auth.getUserId(),
                                        description: "",
                                        modified_at: new Date(),
                                        modified_by: await Auth.getUserId(),
                                        name: "",
                                        working_days: 0
                                    });
                                    setCreateScheduleTemplate(true);
                                }}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    );
                }}
            />
            {createScheduleTemplate || editScheduleTemplate ?
            <Template
                updateTable={updateTable}
                createScheduleTemplate={createScheduleTemplate}
                setCreateScheduleTemplate={setCreateScheduleTemplate}
                editScheduleTemplate={editScheduleTemplate}
                setEditScheduleTemplate={setEditScheduleTemplate}
                scheduleTemplate={scheduleTemplate}
                setScheduleTemplate={setScheduleTemplate}
                phases={phases}
                phaseTasks={phaseTasks}
            /> : null}
            {deleteScheduleTemplate && scheduleTemplate ? 
            <Dialog open={deleteScheduleTemplate}>
                <DialogTitle style={{borderBottom: "2px solid black"}}>Template To Delete</DialogTitle>
                <DialogContent sx={{width: "600px"}}>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Name:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleTemplate.name}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Description:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleTemplate.description}
                        </Box>
                    </Box>
                    <Box style={{display: 'grid', gridTemplateColumns: 'repeat(10, 1fr)', margin: '10px 0 15px'}}>
                        <Box style={{gridColumn: '1/5', fontWeight: 'bold', textAlign: 'end', paddingRight: '5px'}}>
                            Total Days:
                        </Box>
                        <Box style={{gridColumn: '5/11'}}>
                            {scheduleTemplate.total_days}
                        </Box>
                    </Box>
                    {confirmDeleteScheduleTemplate ?
                    <Dialog open={confirmDeleteScheduleTemplate}>
                        <DialogTitle sx={{backgroundColor: 'rgb(255, 255, 150)', fontWeight: 'bold'}}>Confirm Delete Template</DialogTitle>
                        <DialogContent sx={{width: "600px", backgroundColor: 'rgb(255, 255, 150)'}}>
                                Please Confirm Deleting Template
                        </DialogContent>
                        <DialogActions sx={{backgroundColor: 'rgb(255, 255, 150)'}}>
                            <Button variant='contained' onClick={closeWithDelete}>Confirm</Button>
                            <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    : null}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setConfirmDeleteScheduleTemplate(true)}>Delete</Button>
                    <Button variant='contained' onClick={closeNoDelete}>Cancel</Button>
                </DialogActions>
            </Dialog> : null}
        </Box>
    )
};

export default ScheduleTemplate;
