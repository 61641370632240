import {useState} from 'react';
import dayjs from "dayjs";

import Auth from '../../authentication/Auth.js';
import ReporterContainer from '../styles/ReporterContainer';
import Select from "react-select";
import Button  from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    viewer: {
        margin: '20px auto',
        width: '1200px',
        height: '500px',
    },
    page: {
        paddingBottom: '15mm',
        backgroundColor: 'white',
        width: '280mm',
        height: '216mm'
      },
    companyInfo: {
        margin: '7mm auto 0',
        fontSize: '10mm',
        width: '43%',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        borderBottom: '1mm solid black'
    },
    docTitle: {
        margin: '3mm auto 0',
        padding: '2mm 0',
        fontSize: '8mm',
        width: '60%',
        textAlign: 'center',
        fontFamily: 'Helvetica',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15mm',
        paddingRight: '15mm',
        top: '170mm',
        width: '100%',
        fontSize: '3mm',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '2pt',
    },
    columns: {
        marginLeft: '.5mm',
        marginRight: '.5mm',
        paddingTop: '1mm',
        paddingBottom: '1mm',
        fontFamily: 'Helvetica',
        fontSize: '3mm',
        textAlign: 'center',
    },
    dataColumns: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    label1: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data1: {
        textAlign: 'left',
        width: '60mm',
        padding: '.5mm 0'
    },
    label2: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data2: {
        textAlign: 'left',
        width: '30mm',
        padding: '.5mm 0'
    },
    label3: {
        textAlign: 'right',
        width: '20mm',
        padding: '.5mm 1mm .5mm 0'
    },
    data3: {
        textAlign: 'left',
        width: '20mm',
        padding: '.5mm 0'
    }
});

const MaterialUsageLogReport = () => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [phaseTaskDetails, setPhaseTaskDetails] = useState([]);
    const [materialTypes, setMaterialTypes] = useState([]);

    const [materialLogs, setMaterialLogs] = useState([]);

    const [loadParameters, setLoadParameters] = useState({
        materialType: null,
        completedStartDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
            .subtract(dayjs().set('hour', 0).set('minute', 0).set('second', 0).get('day') + 1, 'day'),
        completedEndDate: dayjs().set('hour', 0).set('minute', 0).set('second', 0)
            .add(6 - dayjs().set('hour', 0).set('minute', 0).set('second', 0).get('day'), 'day'),
        invoiced: false,
    })

    const [disableFilterButton, setDisableFilterButton] = useState(false);

    const loadData = async () => {
        setDidLoadData(true);

        const tempPhaseTaskDetails = await Auth.hitEndpointNew('GET', 'phase-task-details');
        setPhaseTaskDetails(tempPhaseTaskDetails);

        const tempMaterialTypes = (await Auth.hitEndpointNew('GET', 'detail-types')).filter(mt => mt.id === 1 || mt.id === 6).map(mt => {
            return {
                ...mt,
                value: mt.id,
                label: mt.name
            }
        });
        setMaterialTypes(tempMaterialTypes);

        if(tempMaterialTypes.length > 0){
            setLoadParameters({...loadParameters, materialType: tempMaterialTypes.find(mt => parseInt(mt.id) === 1)})
        }
    }

    if(!didLoadData){
        loadData();
    }

    const createPourLogReport = async () => {
        setDisableFilterButton(true);

        // Get phase task ids for selected material type and create query string
        const phaseTaskDetailToQuery = phaseTaskDetails.filter(ptd => parseInt(ptd.detail_type_id) === parseInt(loadParameters.materialType.id));
        let queryStr = 'jsonString=(';
        for(let i = 0; i < phaseTaskDetailToQuery.length; i++){
            queryStr += `schedule.phase_task_id=${phaseTaskDetailToQuery[i].phase_task_id}`
            if(i + 1 === phaseTaskDetailToQuery.length){
                queryStr += ')'
            }
            else{
                queryStr += ' or '
            }
        }
        if(loadParameters.completedStartDate !== null){
            queryStr += ` and schedule.schedule_date >= '${dayjs(new Date(loadParameters.completedStartDate)).format('YYYY-MM-DD')}'`;
        }
        if(loadParameters.completedEndDate !== null){
            queryStr += ` and schedule.schedule_date < '${dayjs(new Date(loadParameters.completedEndDate)).format('YYYY-MM-DD')}'`;
        }

        const dbData = (await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, queryStr)).filter(d => {
            const invoice = d.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 1 || parseInt(d.taskDetail.detail_type_id) === 8);
            if(loadParameters.builder_invoiced){
                return invoice.builder_billed;
            }
            return true;
        });

        setMaterialLogs(dbData.map(db => {
            let materialUnit = '';
            if(parseInt(loadParameters.materialType.id) === 1)
                materialUnit = 'yards';
            if(parseInt(loadParameters.materialType.id) === 6)
                materialUnit = 'tons';
            

            const materialDetail = db.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 1);
            const supplierDetail = db.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 2);
            const pumpDetail = db.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 3);
            const timeDetail = db.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 4);
            const orderDetail = db.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 5);

            return {
                id: db.id,
                builder: db.lot.job.builder.name,
                job: `${db.lot.job.number} - ${db.lot.job.project_name}`,
                lot: db.lot.phase ? `${db.lot.number} - ${db.lot.phase}` :  db.lot.number,
                phaseCode: db.task.code,
                phaseDescription: db.task.description,
                orderNumber: orderDetail && orderDetail.schedule_value ? orderDetail.schedule_value : '',
                time: timeDetail && timeDetail.schedule_value ? timeDetail.schedule_value : '',
                supplier: supplierDetail && supplierDetail.supplier && supplierDetail.supplier.name ? supplierDetail.supplier.name  : '',
                pump: pumpDetail && pumpDetail.supplier && pumpDetail.supplier.name ? pumpDetail.supplier.name : '',
                materialAmount: materialDetail && materialDetail.schedule_value ? materialDetail.schedule_value + " " + materialUnit : '',
                completedDate: db.schedule_date !== null ? dayjs(new Date(db.schedule_date)).format('MM-DD-YYYY') : null,
                venderInvoiceNumber: materialDetail && materialDetail.vender_invoice_number ? materialDetail.vender_invoice_number : '',
                venderInvoiceDate: materialDetail && materialDetail.vender_invoice_date ? dayjs(new Date(materialDetail.vender_invoice_date)).format('MM-DD-YYYY') : '',
                venderInvoiceYards: materialDetail && materialDetail.vender_invoice_value ? materialDetail.vender_invoice_value : '',
                venderInvoiceComplete: materialDetail && materialDetail.vender_invoice_complete ? 'X' : '',
                builderInvoiceNumber: materialDetail && materialDetail.builder_invoice_number ? materialDetail.builder_invoice_number : '',
                builderInvoiceComplete: materialDetail && materialDetail.builder_billed ? 'X' : '',
            }
        }))

        setDisableFilterButton(false);
    }

    return (
        <ReporterContainer>
            <Select
                styles={{
                    container: styles => {
                        return {
                            ...styles,
                            width: '300px',
                            justifySelf: 'center',
                            margin: '10px 0',
                            zIndex: '49'
                        }
                    },
                }}
                placeholder="Select Material Type"
                value={loadParameters.materialType}
                onChange={(materialType) => setLoadParameters({...loadParameters, materialType: materialType})}
                options={materialTypes}
            />
            <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                <div style={{marginBottom: "5px"}}>Completed Start Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, completedStartDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                            },
                        }}
                        defaultValue={loadParameters.completedStartDate}
                    />
                </LocalizationProvider>
            </div>
            <div style={{justifySelf: "center", width: "300px", marginBottom: "10px"}}>
                <div style={{marginBottom: "5px"}}>Completed End Date</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => setLoadParameters({...loadParameters, completedEndDate: new Date(date)})}
                        slotProps={{
                            textField: {
                                helperText: '',
                                sx: {width: '100%', minWidth: '120px'},
                            },
                        }}
                        defaultValue={loadParameters.completedEndDate}
                    />
                </LocalizationProvider>
            </div>
            <FormControlLabel 
                sx={{
                    justifySelf: 'center',
                }}
                control={<Checkbox 
                    defaultChecked={loadParameters.invoiced} 
                    onChange={(e) => setLoadParameters({...loadParameters, invoiced: e.target.checked})}/>} 
                label="Show Invoiced" 
            />
            <Button 
                sx={{
                    width: '300px',
                    justifySelf: 'center',
                }}
                disabled={disableFilterButton}
                onClick={() => createPourLogReport()}
                variant='contained'
            >Create Material Usage Log Report</Button>
            <PDFViewer style={styles.viewer}>
                <Document title="Material Usage Log Report">
                    <Page size="LETTER" orientation="landscape" style={styles.page} wrap>
                        <View style={styles.companyInfo} fixed>
                            <Text>Sierra Verde Companies</Text>
                        </View>
                        <View style={styles.docTitle} fixed>
                            <Text>{"Material Usage Log Report" + (loadParameters.materialType ? " - " + loadParameters.materialType.name : '')}</Text>
                        </View>
                        <View style={styles.footer} fixed>
                            <Text>{dayjs(new Date()).format('MM-DD-YYYY')}</Text>
                            <Text render={(page) => `page ${page.pageNumber} of ${page.totalPages}`} />
                        </View>
                        <View style={styles.tableHeader} fixed>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '80mm'}]}>Lot Information</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '50mm'}]}>Phase Task</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '50mm'}]}>Details</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '40mm'}]}>Vender</Text>
                            <Text style={[styles.columns, {backgroundColor: 'rgb(220, 220, 220)', width: '40mm'}]}>Builder</Text>
                        </View>
                        <View wrap>
                            {materialLogs.map((m, index) => {
                                const color = index % 2 === 0 ? 'rgb(250, 250, 204)' : 'white';

                                return (
                                    <View key={`${m.id}-${index}`} style={styles.tableRow} wrap={false}>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '80mm'}]}>
                                            <Text style={[styles.label1]}>Builder:</Text>
                                            <Text style={[styles.data1]}>{m.builder}</Text>
                                            <Text style={[styles.label1]}>Job:</Text>
                                            <Text style={[styles.data1]}>{m.job}</Text>
                                            <Text style={[styles.label1]}>Lot:</Text>
                                            <Text style={[styles.data1]}>{m.lot}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '50mm'}]}>
                                            <Text style={[styles.label2]}>Phase Code:</Text>
                                            <Text style={[styles.data2]}>{m.phaseCode}</Text>
                                            <Text style={[styles.label2]}>Description:</Text>
                                            <Text style={[styles.data2]}>{m.phaseDescription}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '50mm'}]}>
                                            <Text style={[styles.label2]}>Order #:</Text>
                                            <Text style={[styles.data2]}>{m.orderNumber}</Text>
                                            <Text style={[styles.label2]}>Time:</Text>
                                            <Text style={[styles.data2]}>{m.time}</Text>
                                            <Text style={[styles.label2]}>Supplier:</Text>
                                            <Text style={[styles.data2]}>{m.supplier}</Text>
                                            <Text style={[styles.label2]}>Pump:</Text>
                                            <Text style={[styles.data2]}>{m.pump}</Text>
                                            <Text style={[styles.label2]}>Material:</Text>
                                            <Text style={[styles.data2]}>{m.materialAmount}</Text>
                                            <Text style={[styles.label2]}>Completed:</Text>
                                            <Text style={[styles.data2]}>{m.completedDate}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '40mm'}]}>
                                            <Text style={[styles.label3]}>Invoice #:</Text>
                                            <Text style={[styles.data3]}>{m.venderInvoiceNumber}</Text>
                                            <Text style={[styles.label3]}>Date:</Text>
                                            <Text style={[styles.data3]}>{m.venderInvoiceDate}</Text>
                                            <Text style={[styles.label3]}>Yards:</Text>
                                            <Text style={[styles.data3]}>{m.venderInvoiceYards}</Text>
                                            <Text style={[styles.label3]}>Complete:</Text>
                                            <Text style={[styles.data3]}>{m.venderInvoiceComplete}</Text>
                                        </View>
                                        <View style={[styles.columns, styles.dataColumns, {backgroundColor: color, width: '40mm'}]}>
                                            <Text style={[styles.label3]}>Invoice #:</Text>
                                            <Text style={[styles.data3]}>{m.builderInvoiceNumber}</Text>
                                            <Text style={[styles.label3]}>Billed:</Text>
                                            <Text style={[styles.data3]}>{m.builderInvoiceComplete}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>

        </ReporterContainer>
    );
}

export default MaterialUsageLogReport;