import { useState } from 'react';
import Auth from '../authentication/Auth.js';
import LotNotes from '../lots/LotNotes.js';
import dayjs from 'dayjs';
import FieldMenu from "./FieldMenu";
import FieldFormsContainer from "./styles/FieldFormsContainer";
import JobLotFilter from "./JobLotFilter";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Button from '@mui/material/Button';

const TopOutTrimRelease = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [lots, setLots] = useState([]);
    const [selectedLot, setSelectedLot] = useState(null);

    const [phases, setPhases] = useState([]);
    const [topoutTrimReleaseInformation, setTopoutTrimReleaseInformation] = useState(null);

    const [displayNotes, setDisplayNotes] = useState(false);
    const [noteType, setNoteType] = useState('');

    const canReleaseTopOutTrim = props.isAuthorized('topOutAndTrimRelease', 'WRITE');

    // Get date 30 days ago for trim release
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const loadData = async () => {
        setDidLoadData(true);
        setPhases(await Auth.hitEndpointNew('GET', 'phases'));
    }

    if (!didLoadData) {
        loadData();
    }

    const lotSelected = async (lot) => {
        const tempSelectedLot = lot === null ? null : lots.find(l => l.id.toString() === lot.value.toString());
        if(tempSelectedLot) {
            const topoutPhase = tempSelectedLot.phases.find(p => parseInt(p.phase_id) === 4);
            const trimPhase = tempSelectedLot.phases.find(p => parseInt(p.phase_id) === 5);
            setTopoutTrimReleaseInformation({
                topoutReleaseDate: topoutPhase !== undefined ? topoutPhase.release_date : null,
                topoutStartsStatus: topoutPhase !== undefined ? topoutPhase.starts_status : '',
                trimReleaseDate: trimPhase !== undefined ? trimPhase.release_date : null,
                trimStartsStatus: trimPhase !== undefined ? trimPhase.starts_status : ''
            });
        }
        return tempSelectedLot
    }

    const releasePhase = async (phase_id) => {
        let topoutPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 4);
        let trimPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 5);
        
        if(phase_id === 4){
            if(topoutPhase === undefined){
                selectedLot.phases.push({
                    lot_id: selectedLot.id,
                    modified_at: new Date(),
                    modified_by: await Auth.getUserId(),
                    phase: phases.find(p => parseInt(p.id) === 4),
                    phase_id: 4,
                    release_date: null,
                    start_status_id: null,
                    starts_status: '',
                });
                topoutPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 4);
            }
            topoutPhase.release_date = new Date();
            if(topoutPhase.starts_status === '' || topoutPhase.starts_status === null){
                topoutPhase.starts_status = 'Received';
                topoutPhase.start_status_id = 1;
            }
        }
        if(phase_id === 5){
            if(trimPhase === undefined){
                selectedLot.phases.push({
                    lot_id: selectedLot.id,
                    modified_at: new Date(),
                    modified_by: await Auth.getUserId(),
                    phase: phases.find(p => parseInt(p.id) === 5),
                    phase_id: 5,
                    release_date: null,
                    start_status_id: null,
                    starts_status: '',
                });
                trimPhase = selectedLot.phases.find(p => parseInt(p.phase_id) === 5);
            }
            trimPhase.release_date = new Date();
            if(trimPhase.starts_status === '' || trimPhase.starts_status === null){
                trimPhase.starts_status = 'Received';
                trimPhase.start_status_id = 1;
            }
        }
        const dbObj = {
            id: selectedLot.id,
            phases: selectedLot.phases.map(p => {
                return {
                    id: p.id !== undefined && p.id !== null ? p.id : null,
                    lot_id: p.lot_id,
                    modified_at: p.modified_at !== null ? dayjs(new Date(p.modified_at)).format('YYYY-MM-DD') : null,
                    modified_by: p.modified_by,
                    phase_id: p.phase_id,
                    release_date: p.release_date !== null ? dayjs(new Date(p.release_date)).format('YYYY-MM-DD') : null,
                    start_status_id: p.start_status_id,
                    starts_status: p.starts_status,
                }
            })
        }
        await Auth.hitEndpointNew('PATCH', 'lot/no-processing', '', dbObj);

        setTopoutTrimReleaseInformation({
            topoutReleaseDate: topoutPhase !== undefined ? topoutPhase.release_date : null,
            topoutStartsStatus: topoutPhase !== undefined ? topoutPhase.starts_status : '',
            trimReleaseDate: trimPhase !== undefined ? trimPhase.release_date : null,
            trimStartsStatus: trimPhase !== undefined ? trimPhase.starts_status : ''
        });
    }

    const handleDisplayNotes = () => {
        if(displayNotes){
            setDisplayNotes(false);
        }
        else{
            setDisplayNotes(true);
        }
    }

    const closeNotes = (lot) => {
        setDisplayNotes(false);
        setNoteType('');
    }



    // Set Trim grayed out button text if needed
    let trimButtonText = 'Error';
    if(topoutTrimReleaseInformation){
        if(topoutTrimReleaseInformation.topoutReleaseDate === null){
            trimButtonText = 'Top Out Not Released'
        }
        else if(topoutTrimReleaseInformation.trimReleaseDate !== null){
            trimButtonText = 'Released'
        }
        else if(thirtyDaysAgo < new Date(topoutTrimReleaseInformation.topoutReleaseDate)){
            const thirtyDaysFromTopOutRelease = new Date(topoutTrimReleaseInformation.topoutReleaseDate);
            thirtyDaysFromTopOutRelease.setDate(thirtyDaysFromTopOutRelease.getDate() + 30);
            const daysUntilOpen = Number.parseInt((thirtyDaysFromTopOutRelease.getTime() - today.getTime()) / 1000 / 60 / 60 / 24);
            trimButtonText = `${daysUntilOpen} Days Until Available`
        }
    }

    return (
        <FieldFormsContainer>
            <FieldMenu/>
            <JobLotFilter
                title={'Top Out & Trim Release'}
                jobs={jobs}
                setJobs={setJobs}
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                lots={lots}
                setLots={setLots}
                selectedLot={selectedLot}
                setSelectedLot={setSelectedLot}
                lotSelected={lotSelected}
            />
            {selectedLot && topoutTrimReleaseInformation && 
            <Box
                sx={{
                    background: 'rgb(210, 200, 190)',
                    padding: '0 0 15px',
                    margin: '3px 0 100px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderBottom: '3px solid white'
                    }}
                >Top Out</Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Release Date:</Box>
                    {topoutTrimReleaseInformation.topoutReleaseDate ?
                    <Box>{dayjs(new Date(topoutTrimReleaseInformation.topoutReleaseDate)).format('MM-DD-YYYY')}</Box> :
                    <Box>Not Released</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Starts Status:</Box>
                    {topoutTrimReleaseInformation.topoutStartsStatus ?
                    <Box>{topoutTrimReleaseInformation.topoutStartsStatus}</Box>:
                    <Box>N/A</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Lot Notes:</Box>
                    <Tooltip title="View/Edit Notes">
                        <TextSnippetIcon 
                            sx={{cursor: 'pointer'}}
                            onClick={() => {
                                handleDisplayNotes(); 
                                setNoteType('TopOut');
                            }}
                        />
                    </Tooltip>
                </Box>
                {canReleaseTopOutTrim && 
                <Box
                    sx={{
                        display: 'grid',
                        justifyContent: 'center',
                        margin: '10px 0'
                    }}
                >
                    <Button 
                        variant='contained' 
                        onClick={() => releasePhase(4)}
                        disabled={topoutTrimReleaseInformation.topoutReleaseDate !== null}
                    >
                        {topoutTrimReleaseInformation.topoutReleaseDate === null ? 'Release Top Out' : 'Top Out Released'}
                    </Button>
                </Box>}
                <Box
                    sx={{
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        background: 'rgb(128, 128, 128)',
                        padding: '3px',
                        borderTop: '3px solid white',
                        borderBottom: '3px solid white'
                    }}
                >Trim</Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Release Date:</Box>
                    {topoutTrimReleaseInformation.trimReleaseDate ?
                    <Box>{dayjs(new Date(topoutTrimReleaseInformation.trimReleaseDate)).format('MM-DD-YYYY')}</Box> :
                    <Box>Not Released</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Starts Status:</Box>
                    {topoutTrimReleaseInformation.trimStartsStatus ?
                    <Box>{topoutTrimReleaseInformation.trimStartsStatus}</Box>:
                    <Box>N/A</Box>}
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            alignSelf: 'center',
                            textAlign: 'end',
                            paddingRight: '5px'
                        }}
                    >Lot Notes:</Box>
                    <Tooltip title="View/Edit Notes">
                        <TextSnippetIcon 
                            sx={{cursor: 'pointer'}}
                            onClick={() => {
                                handleDisplayNotes(); 
                                setNoteType('Trim');
                            }}
                        />
                    </Tooltip>
                </Box>
                {canReleaseTopOutTrim && 
                <Box
                    sx={{
                        display: 'grid',
                        justifyContent: 'center',
                        margin: '10px 0'
                    }}
                >
                    <Button 
                        variant='contained' 
                        onClick={() => releasePhase(5)}
                        disabled={topoutTrimReleaseInformation.topoutReleaseDate === null ||
                            thirtyDaysAgo < new Date(topoutTrimReleaseInformation.topoutReleaseDate) ||
                            topoutTrimReleaseInformation.trimReleaseDate !== null
                        }
                    >
                        {topoutTrimReleaseInformation.topoutReleaseDate !== null &&
                        thirtyDaysAgo > new Date(topoutTrimReleaseInformation.topoutReleaseDate) &&
                        topoutTrimReleaseInformation.trimReleaseDate === null ? 'Release Trim' : trimButtonText}
                    </Button>
                </Box>}
            </Box>}
            {displayNotes &&
            <LotNotes
                openLotNotes={displayNotes}
                setOpenLotNotes={setDisplayNotes}
                lotToViewNotes={selectedLot}
                setLotToViewNotes={() => {}}
                noteType={noteType}
                updatedLotReturned={closeNotes}
            />}
        </FieldFormsContainer>
    )
}

export default TopOutTrimRelease;