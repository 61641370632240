import md5 from "md5";
import {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../authentication/Auth.js';
import logo from './../../img/HeaderLogo.png';
import LoginContainer from './styles/LoginContainer.js';
import { AppContext } from "../../context/AppProvider"
import {logError} from "../../utilities/error";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button';

const Login = (props) => {
    const [authenticateUser, setAuthenticateUser] = useState(false);
    const [userData, setUserData] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');
    
    const navigate = useNavigate();

    useEffect(() => {
        const authenticate = async () => {
            try {
                // Checks password and creates JWT
                const returnedData = await Auth.hitEndpoint("POST", "LOGIN", "", userData);
                if(!returnedData.success){
                    setError('You entered a wrong username or password');
                    setUserData({...userData, password: ''});
                    return;
                }
                // Inform Auth about successful login and send token
                Auth.login(returnedData);
                try{
                    props.setInitialTimeout();
                }
                catch(e){
                    logError(e)
                }

                setIsAuthenticated(true)
                setState(true)
                if(userData.username === 'svcfield'){
                    navigate('/daily-schedule');
                }
                else {
                    navigate("/home");
                }

            } catch (error) {
                setError('You entered a wrong username or password');
                setUserData({...userData, password: ''});
                logError(error);
            }
        }

        if(authenticateUser){
            authenticate();
            setAuthenticateUser(false);
        }
    }, [authenticateUser, userData, navigate, props]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if(userData.username.length < 1){
            setError('Please enter a username')
            document.getElementById("username").focus();
            return;
        }
        if(userData.password.length < 1){
            setError('Please enter a password')
            document.getElementById("password").focus();
            return;
        }

        setUserData({...userData, username: userData.username.toLowerCase(), password: md5(userData.password)});
        setAuthenticateUser(true);

    }

    const { isAuthenticated, setIsAuthenticated, state, setState } = useContext(AppContext);

    return (
        <LoginContainer>
            <div className="login-logo"><img src={logo} alt="SVC Logo"/></div>
            <div className='login-header'>Login</div>
            <form id="login" onSubmit={handleSubmit}>
                <input type="text" autoComplete="username" defaultValue={userData.username} hidden/>
                <div className="login-textbox username-textbox">
                    <label className="login-label" htmlFor="username">User Name</label>
                    <input className="login-input" type="username" autoComplete="username"
                           id="username" name="username" placeholder="Enter Username" value={userData.username}
                        onChange={((e) => setUserData({ ...userData, username: e.target.value }))}/>
                </div>
                <div className="login-textbox password-textbox">
                    <label className="login-label" htmlFor="password">Password</label>
                    <input className="login-input" type="password" autoComplete="password"
                           id="password" name="password" placeholder="Enter Password" value={userData.password}
                        onChange={((e) => setUserData({ ...userData, password: e.target.value }))}/>
                </div>
                <input className="form-submit" type="submit" value="Login"/>
            </form>
            {error !== '' &&
            <Dialog open={error !== ''}>
                <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>LOGIN ERROR</DialogTitle>
                <DialogContent 
                    sx={{width: "350px", backgroundColor: 'rgb(255, 150, 150)'}}>{error}</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                    <Button 
                        autoFocus
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => setError('')}
                    >OK</Button>
                </DialogActions>
            </Dialog>}
        </LoginContainer>
    );
}

export default Login;
