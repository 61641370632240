
import {useMemo, useState} from "react";

import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {MaterialReactTable} from "material-react-table";
import {darken, lighten, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import EvenflowGridContainer from "./styles/EvenflowGridContainer";

const EvenflowGrid = ({selectedBuilder, template, evenflow}) => {

    const [selectedTab, setSelectedTab] = useState("0")
    const [bypassPagination, setBypassPagination] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageIndex: 0
    })

    const Item = styled(Paper)(({ thm, bgcolor}) => {
    return(
        {
            width: '100%',
            fontSize: '10pt',
            backgroundColor: bgcolor,
            textAlign: 'center',
            color: '#1A2027',
            borderRadius: '0',
            padding: '0pt'
        })})

    const AvgItem = styled(Paper)(({ thm, bgcolor}) => {
        return(
            {
                width: '50%',
                fontSize: '10pt',
                backgroundColor: bgcolor,
                textAlign: 'center',
                color: '#1A2027',
                borderRadius: '0',
                padding: '0pt'
            })})

    const evenflowColumns = useMemo(
        () => {
            let columns = [
                {
                    accessorFn: (row) => {
                        return(row.lot)
                    },
                    header: 'Lot',
                    id: 'lot',
                    size: 150,
                },
                {
                    accessorFn: (row) => {
                        return (
                            <Grid key={`lot-cell-grid-${row.index}-${row.index}-schedule-actual`} container columns={{xs: 4, md: 12}} className={`labels-grid`}>
                                <Item key={`lot-cell-grid-item-due-${row.index}-${row.index}-schedule`} bgcolor={'#FFFFFF'} className={'schedule'}>{'Sched'}</Item>
                                <Item key={`lot-cell-grid-item-scheduled-${row.index}-${row.index}`} bgcolor={'#FFFFFF'} className={'actual'}>{'Actual'}</Item>
                            </Grid>
                        )
                    },
                    header: '',
                    id: 'schedule_actual',
                    size: 100,
                },
            ]
            for (let t = 0; t < template.length; t++) {
                let item = template[t]
                let taskName = (`${item.task.code !== null ? item.task.code : ''} ${item.task.name}`).trim()
                let taskId = item.task.id
                let calcDaysStop = item.calc_days_stop
                let calcDaysReset = item.calc_days_reset
                columns.push(
                    {
                        accessorFn: (row) => {
                            let schedule = row[taskName] === undefined || row[taskName].schedule === null ? '' : row[taskName].schedule
                            let actual = row[taskName] === undefined || row[taskName].actual === null ? schedule === '' ? '' : ' - ' : row[taskName].actual
                            let bgColor = getBgColor(row[taskName], false, taskName)
                            let isAvg = row[taskName] === undefined ? false : row[taskName].is_avg
                            return (
                                <Grid key={`lot-cell-grid-${row.index}-${row.index}`} container columns={{xs: 4, md: 12}} className={row[taskName] === undefined ? '' : `dates-grid`}>
                                    <Item key={`lot-cell-grid-item-due-${row.index}-${row.index}`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{schedule}</Item>
                                    <Item key={`lot-cell-grid-item-scheduled-${row.index}-${row.index}`} bgcolor={bgColor} className={'actual'}>{actual}</Item>
                                </Grid>
                            )
                        },
                        id: taskName,
                        header: taskName,
                        enableEditing: false,
                        size: 200,
                    })
                if(calcDaysStop) {
                    columns.push(
                        {
                            accessorFn: (row) => {
                                let bgColor = getBgColor(row[item.calc_days_label], true, item.calc_days_label)
                                let sDays = row[item.calc_days_label].schedule_days
                                let sLabel = row[item.calc_days_label].schedule
                                let aDays = row[item.calc_days_label].actual_days === null ? ' - ' : row[item.calc_days_label].actual_days
                                let isAvg = row[item.calc_days_label] === undefined ? false : row[item.calc_days_label].is_avg
                                let dDays = isAvg ? row[item.calc_days_label].delay_days > 0 ? row[item.calc_days_label].delay_days : '' : ''
                                return (
                                    <Grid key={`lot-cell-grid-${row.index}-${row.index}-totals`} container columns={{xs: 4, md: 12}} className={row[item.calc_days_label] === undefined ? '' : `calc-grid`}>
                                        <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-schedule`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{sLabel === 'Avg' ? sLabel : sDays}</AvgItem>
                                        <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-schedule-delay`} bgcolor={isAvg ? '#FFFFFF' : '#D3D3D3'} className={'schedule'}>{sLabel === 'Avg' && dDays !== '' ? 'Avg Delay' : ''}</AvgItem>
                                        <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-actual`} bgcolor={bgColor} className={'actual'}>{aDays}</AvgItem>
                                        <AvgItem key={`lot-cell-grid-item-due-${row.index}-${row.index}-total-actual-delay`} bgcolor={bgColor} sx={{color: 'red'}} className={'actual'}>{dDays !== '' ? ` ${dDays}` : ''}</AvgItem>
                                    </Grid>
                                )
                            },
                            id: item.calc_days_label,
                            header: item.calc_days_label,
                            enableEditing: false,
                            size: 200,
                            hidden: true,
                        })
                }
            }
            return columns
        }
    );

    const getBgColor = (item, isTotal=false, label = '') => {
        let bgColor = '#FFFFFF'
        if (item === undefined || (item.schedule === null && !item.is_avg)){
            bgColor = '#808080'
        }
        else if (!item.is_avg && (item.actual !== null || isTotal)) {
            if(item.actual_days > 0) {
                if (item.actual_days <= item.schedule_days) {
                    bgColor = '#71D9E2'
                } else {
                    bgColor = '#FFC0CB'
                }
            }
        }
        return bgColor
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    return (
        <EvenflowGridContainer>
            {(evenflow === undefined || evenflow.jobs.length === 0) &&
                <span>No Data To Display</span>
            }
            {evenflow !== undefined &&
                <TabContext value={selectedTab}>
                    <TabList
                        scrollButtons
                        allowScrollButtonsMobile
                        onChange={handleTabChange}
                        variant="scrollable"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {evenflow.jobs.map((row, index) => {
                            return (
                                <Tab key={`job-tab-${index}`} className={'tab-active'} label={row.job}
                                     value={index.toString()}/>
                            )
                        })}
                    </TabList>
                    {evenflow.jobs.map((row, index) => {
                        return (
                            <TabPanel key={`job-panel-${index}`} value={index.toString()}>
                                <div key={`job-panel-div-${index}`} style={{marginTop: "0px"}}>
                                    <MaterialReactTable
                                        data={row.lots}
                                        columns={evenflowColumns}
                                        enableStickyHeader
                                        rowPinningDisplayMode={'top'}
                                        keepPinnedRows={false}
                                        enableColumnFilters={false}
                                        state={{pagination}}
                                        onPaginationChange={(page) => {
                                            if(!bypassPagination){
                                                setPagination(page);
                                            }
                                            setBypassPagination(false);
                                        }}
                                        muiTableContainerProps={{ sx: { maxHeight: '700px', maxWidth: '100vw' } }}
                                        muiTableHeadCellProps={({column, table}) => {
                                           let len = table.getRowModel().rows.filter(x => x.original[`has_${column.id}`]).length
                                            return({
                                                sx: {
                                                    backgroundColor: column.id === 'lot' || column.id === 'schedule_actual' || column.id.includes('Total') || len > 0 ? '#FFFFFF' : '#808080',
                                                },
                                                className: column.id === 'lot' ? 'lot-column' : ''
                                            })}}
                                        muiTableBodyCellProps={({cell, row}) => {
                                            let bgColor = cell.column.id === 'lot' || cell.column.id === 'schedule_actual' || cell.column.id.includes('Total') ?
                                                '#FFFFFF' : getBgColor(row.original[cell.column.id], false, cell.column.id)
                                            return({
                                            sx: {
                                                height: 10,
                                                fontStyle: 'italic',
                                                backgroundColor: bgColor,
                                                borderBottom: 'solid 2q',
                                                borderTop: 'solid 2q',
                                                borderLeft: 'solid 1q',
                                                borderRight: 'solid 1q',
                                                paddingBottom: '1px',
                                                contentAlign: 'center',
                                                textAlign: 'center',
                                            }
                                        })}}
                                        initialState={{
                                            showColumnFilters: false,
                                            pagination: pagination,
                                            columnPinning: {
                                                left: ['lot', 'schedule_actual']
                                            },
                                            density: 'compact',
                                            expanded: false,
                                            showGlobalFilter: true,
                                        }}
                                    />
                                </div>
                            </TabPanel>
                        )
                    })}
                </TabContext>
            }
        </EvenflowGridContainer>
    )
}

export default EvenflowGrid;
