import dayjs from "dayjs";
import Auth from "../components/authentication/Auth";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class Field {

    static async loadEvenflow(builderIds, startDate) {

        let json = {
            "builder.id": builderIds
        }

        let jsonString = JSON.stringify(json)

        let dbData = await Auth.hitEndpointNew("GET", `evenflow/${startDate.format('YYYY-MM-DD')}`, jsonString !== '' ? `jsonString=${jsonString}` : null);
        return dbData;
    }
}

export default Field;