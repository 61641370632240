import { useMemo, useState} from 'react';
import dayjs from "dayjs";
import Auth from '../authentication/Auth.js';
import {MaterialReactTable} from "material-react-table";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button  from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import { Grid, TextField } from '@mui/material';
import { Switch } from '@mui/material';
import Select from "react-select";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const MaterialUsageLog = (props) => {
    const [didLoadData, setDidLoadData] = useState(false);

    const [filters, setFilters] = useState({
        materialType: null,
        job: null,
        lot: null,
        scheduledDate: null,
        completedDate: null,
        builder_invoiced: false,
        vender_invoiced: false
    });

    const [cursor, setCursor] = useState('default');
    const [disableFilterButton, setDisableFilterButton] = useState(false);

    const [phaseTaskDetails, setPhaseTaskDetails] = useState([]);
    const [materialTypes, setMaterialTypes] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [lots, setLots] = useState([]);

    const [materialLogs, setMaterialLogs] = useState([]);
    const [editMaterialLog, setEditMaterialLog] = useState(null);

    const [error, setError] = useState({
        type: '',
        message: ''
    });

    const canEditInvoice = props.isAuthorized('materialUsageLog', 'WRITE');

    const columns = useMemo(
        () => [
            {
                id: 'job_number',
                accessorKey: 'job_number',
                header: 'Job #',
                size: 150
            },
            {
                id: 'lot_number',
                accessorKey: 'lot_number',
                header: 'Lot #',
                size: 200
            },
            {
                id: 'phase_task',
                accessorKey: 'phase_task',
                header: 'Phase Task',
                size: 200
            },
            {
                accessorKey: 'builder',
                header: 'Builder',
                size: 250
            },
            {
                accessorKey: 'project_name',
                header: 'Project',
                size: 300
            },
            {
                accessorFn: (row) => row.material !== null ? row.material + " " + row.material_unit : '',
                header: 'Material Amount',
                size: 200
            },
            {
                accessorKey: 'supplier',
                header: 'Supplier',
                size: 200
            },
            {
                accessorKey: 'pump',
                header: 'Pump',
                size: 250
            },
            {
                accessorKey: 'time',
                header: 'Time',
                size: 150
            },
            {
                accessorKey: 'order',
                header: 'Order #',
                size: 180
            },
            {
                accessorKey: 'schedule_date',
                header: 'Scheduled',
                size: 200
            },
            {
                accessorKey: 'completed_date',
                header: 'Completed',
                size: 200
            },
            {
                accessorKey: 'builder_invoice_number',
                header: 'Builder Invoice #',
                size: 250
            },
            {
                id: 'builder_billed',
                accessorFn: (row) => row.builder_billed ? 'X' : '', 
                header: 'Builder Billed',
                size: 200
            },
            {
                accessorKey: 'builder_invoice_date',
                header: 'Builder Invoice Date',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_number',
                header: 'Vender Invoice #',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_value',
                header: 'Vender Invoice Yards',
                size: 250
            },
            {
                id: 'vender_invoice_complete',
                accessorFn: (row) => row.vender_invoice_complete ? 'X' : '',
                header: 'Vender Invoice Complete',
                size: 250
            },
            {
                accessorKey: 'vender_invoice_date',
                header: 'Vender Invoice Date',
                size: 250
            },
        ],
        [],
    );

    const loadData = async () => {
        setDidLoadData(true);

        const tempPhaseTaskDetails = await Auth.hitEndpointNew('GET', 'phase-task-details');
        setPhaseTaskDetails(tempPhaseTaskDetails);

        const tempMaterialTypes = await Auth.hitEndpointNew('GET', 'detail-types');
        setMaterialTypes(tempMaterialTypes.filter(mt => mt.id === 1 || mt.id === 6).map(mt => {
            return {
                ...mt,
                value: mt.id,
                label: mt.name
            }
        }));

        const tempJobs = await Auth.hitEndpointNew('GET', 'jobs-new');
        setJobs(tempJobs.map(j => {
            return {
                ...j,
                value: j.id,
                label: `${j.number} - ${j.builder.name} - ${j.project_name}`
            }
        }).sort((j1, j2) => parseInt(j1.number) < parseInt(j2.number) ? -1 : 1));
    }

    if(!didLoadData){
        loadData();
    }

    const handleJobSelect = async (job) => {
        if(job === null){
            setFilters({...filters, job: null, lot: null});
            return;
        }

        setDisableFilterButton(true);
        const tempLots = await Auth.hitEndpointNew('GET', 'lots/index-new', `queryString=lot.job_id=${job.id}`);
        setLots(tempLots.map(l => {
            return {
                ...l,
                value: l.id,
                label: `${l.number} - ${l.phase} - ${l.address}`
            }
        }));
        setFilters({...filters, job: job});
        setDisableFilterButton(false);
    }

    const handleUpdateTable = async () => {
        if(filters.materialType === null){
            setError({
                type: 'NO MATERIAL TYPE',
                message: 'No material type filter selected.'
            });
            return;
        }
        
        // Get phase task ids for selected material type and create query string
        const phaseTaskDetailToQuery = phaseTaskDetails.filter(ptd => parseInt(ptd.detail_type_id) === parseInt(filters.materialType.id));

        let queryStr = 'jsonString=(';
        for(let i = 0; i < phaseTaskDetailToQuery.length; i++){
            queryStr += `schedule.phase_task_id=${phaseTaskDetailToQuery[i].phase_task_id}`
            if(i + 1 === phaseTaskDetailToQuery.length){
                queryStr += ')'
            }
            else{
                queryStr += ' or '
            }
        }
        queryStr += ' and schedule.complete = 1';

        if(filters.job !== null){
            queryStr += ` and lot.job_id = ${filters.job.id}`
        }
        if(filters.lot !== null){
            queryStr += ` and lot.id = ${filters.lot.id}`
        }
        if(filters.scheduledDate != null){
            const dayMinusOne = dayjs(filters.scheduledDate).add(-1, 'day').format('YYYY-MM-DD');
            const dayPlusOne = dayjs(filters.scheduledDate).add(1, 'day').format('YYYY-MM-DD');
            queryStr += ` and schedule.schedule_date > '${dayMinusOne}' and schedule.schedule_date < '${dayPlusOne}'`
        }
        if(filters.completedDate != null){
            const dayMinusOne = dayjs(filters.completedDate).add(-1, 'day').format('YYYY-MM-DD');
            const dayPlusOne = dayjs(filters.completedDate).add(1, 'day').format('YYYY-MM-DD');
            queryStr += ` and schedule.completed_date > '${dayMinusOne}' and schedule.completed_date < '${dayPlusOne}'`
        }
        const dbData = (await Auth.hitEndpointNew("GET", `schedule/detail/reconcile`, queryStr)).filter(d => {
            const invoice = d.detail.find(d => parseInt(d.taskDetail.detail_type_id) === 1 || parseInt(d.taskDetail.detail_type_id) === 6);
            if(invoice !== undefined && !filters.builder_invoiced){
                return !invoice.builder_billed;
            }
            if(invoice !== undefined && !filters.vender_invoiced){
                return !invoice.vender_invoice_complete;
            }
            return true;
        });

        const tempMaterialLogs = [];
        for(let i = 0; i < dbData.length; i++){
            let material = null;
            let materialUnit = null;
            let supplier = null;
            let pump = null;
            let time = null;
            let order = null;

            // Material is concrete
            if(parseInt(filters.materialType.id) === 1){
                material = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 1);
                materialUnit = 'yards';
                supplier = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 2);
                pump = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 3);
                time = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 4);
                order = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 5);
            }
            // Material is ABC
            if(parseInt(filters.materialType.id) === 6){
                material = dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 6);
                materialUnit = 'tons';
                supplier = undefined; // dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 2);
                pump = undefined; // dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 3);
                time = undefined; // dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 4);
                order = undefined; // dbData[i].detail.find(d => parseInt(d.taskDetail.detail_type_id) === 5);
            }

            tempMaterialLogs.push({
                builder: dbData[i].lot.job.builder.name,
                job_number: dbData[i].lot.job.number,
                project_name: dbData[i].lot.job.project_name,
                lot_number: dbData[i].lot.number,
                phase_task_id: dbData[i].task.id,
                phase_task: `${dbData[i].task.code} - ${dbData[i].task.description}`,
                schedule_date: dbData[i].schedule_date !== null ? dayjs(new Date(dbData[i].schedule_date)).format('MM-DD-YYYY') : null,
                completed_date: dbData[i].completed_date !== null ? dayjs(new Date(dbData[i].completed_date)).format('MM-DD-YYYY') : null,
                id: dbData[i].id,
                material: material && material.schedule_value ? material.schedule_value : null,
                material_unit: materialUnit,
                supplier: supplier && supplier.supplier && supplier.supplier.name ? supplier.supplier.name  : null,
                pump: pump && pump.supplier && pump.supplier.name ? pump.supplier.name : null,
                time: time && time.schedule_value ? time.schedule_value : null,
                order: order && order.schedule_value ? order.schedule_value : null,
                builder_invoice_number: material ? material.builder_invoice_number : null,
                builder_billed: material && material.builder_billed ? true : false,
                builder_invoice_date: material && material.builder_invoice_date ? dayjs(new Date(material.builder_invoice_date)).format('MM-DD-YYYY') : null,
                builder_invoice_by: material ? material.builder_invoice_by : null,
                vender_invoice_number: material ? material.vender_invoice_number : null,
                vender_invoice_value: material ? material.vender_invoice_value : null,
                vender_invoice_complete: material && material.vender_invoice_complete ? true : false,
                vender_invoice_date: material && material.vender_invoice_date ? dayjs(new Date(material.vender_invoice_date)).format('MM-DD-YYYY') : null,
                vender_invoice_by: material ? material.vender_invoice_by : null,
                details: dbData[i].detail
            })
        }
        setMaterialLogs([...tempMaterialLogs]);
    }

    const createMaterialLogScheduleLogEntry = (originalMaterialLog, newMaterialLog, column, action, userId) => {
        return {
            schedule_id: newMaterialLog.id,
            action: action,
            original_value: originalMaterialLog[column],
            new_value: newMaterialLog[column],
            action_date: dayjs(new Date()).format('YYYY-MM-DD'),
            action_by: userId
        }
    }

    const handleCloseWithSave = async (materialLog) => {
        const originalMaterialLog = materialLogs.find(pl => parseInt(pl.id) === parseInt(materialLog.id));

        // Build Change Log
        const userId = await Auth.getUserId();
        const changeLog = [];
        if(originalMaterialLog.builder_billed !== materialLog.builder_billed){
            changeLog.push(createMaterialLogScheduleLogEntry(originalMaterialLog, materialLog, 'builder_billed', 'changed builder invoice billed', userId))
        }
        if(originalMaterialLog.builder_invoice_number !== materialLog.builder_invoice_number){
            changeLog.push(createMaterialLogScheduleLogEntry(originalMaterialLog, materialLog, 'builder_invoice_number', 'changed builder invoice number', userId))
        }
        if(originalMaterialLog.vender_invoice_complete !== materialLog.vender_invoice_complete){
            changeLog.push(createMaterialLogScheduleLogEntry(originalMaterialLog, materialLog, 'vender_invoice_complete', 'changed vender invoice complete', userId))
        }
        if(originalMaterialLog.vender_invoice_number !== materialLog.vender_invoice_number){
            changeLog.push(createMaterialLogScheduleLogEntry(originalMaterialLog, materialLog, 'vender_invoice_number', 'changed vender invoice number', userId))
        }
        if(originalMaterialLog.vender_invoice_value !== materialLog.vender_invoice_value){
            changeLog.push(createMaterialLogScheduleLogEntry(originalMaterialLog, materialLog, 'vender_invoice_value', 'changed vender invoice value', userId))
        }
        for(let i = 0; i < changeLog.length; i++){
            await Auth.hitEndpointNew('POST', 'schedule/log', '', changeLog[i]);
        }

        // Create details
        const details = materialLog.details.map(d => {
            if(d.taskDetail.detail_type_id === 1 || d.taskDetail.detail_type_id === 6){
                return {
                    id: d.id,
                    builder_billed: materialLog.builder_billed ? true : false,
                    builder_invoice_number: materialLog.builder_invoice_number,
                    builder_invoice_by: materialLog.builder_invoice_by,
                    builder_invoice_date: materialLog.builder_invoice_date !== null ? dayjs(new Date(materialLog.builder_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_by: materialLog.vender_invoice_by,
                    vender_invoice_complete: materialLog.vender_invoice_complete ? true : false,
                    vender_invoice_date: materialLog.vender_invoice_date !== null ? dayjs(new Date(materialLog.vender_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_number: materialLog.vender_invoice_number,
                    vender_invoice_value: materialLog.vender_invoice_value
                }
            }
            return {id: d.id}
        })

        // Attach material detail if one does not exist
        if(parseInt(filters.materialType.id) === 1){
            if(!materialLog.details.find(d => parseInt(d.taskDetail.detail_type_id) === 1)){
                const phaseTaskDetail = phaseTaskDetails.find(ptd => parseInt(ptd.phase_task_id) === parseInt(materialLog.phase_task_id) && parseInt(ptd.detail_type_id) === 1)
                details.push({
                    phase_task_detail_id: phaseTaskDetail ? phaseTaskDetail.id : null,
                    builder_billed: materialLog.builder_billed ? true : false,
                    builder_invoice_number: materialLog.builder_invoice_number,
                    builder_invoice_by: materialLog.builder_invoice_by,
                    builder_invoice_date: materialLog.builder_invoice_date !== null ? dayjs(new Date(materialLog.builder_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_by: materialLog.vender_invoice_by,
                    vender_invoice_complete: materialLog.vender_invoice_complete ? true : false,
                    vender_invoice_date: materialLog.vender_invoice_date !== null ? dayjs(new Date(materialLog.vender_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_number: materialLog.vender_invoice_number,
                    vender_invoice_value: materialLog.vender_invoice_value
                })
            }
        }
        if(parseInt(filters.materialType.id) === 6){
            if(!materialLog.details.find(d => parseInt(d.taskDetail.detail_type_id) === 6)){
                const phaseTaskDetail = phaseTaskDetails.find(ptd => parseInt(ptd.phase_task_id) === parseInt(materialLog.phase_task_id) && parseInt(ptd.detail_type_id) === 6)
                details.push({
                    phase_task_detail_id: phaseTaskDetail ? phaseTaskDetail.id : null,
                    builder_billed: materialLog.builder_billed ? true : false,
                    builder_invoice_number: materialLog.builder_invoice_number,
                    builder_invoice_by: materialLog.builder_invoice_by,
                    builder_invoice_date: materialLog.builder_invoice_date !== null ? dayjs(new Date(materialLog.builder_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_by: materialLog.vender_invoice_by,
                    vender_invoice_complete: materialLog.vender_invoice_complete ? true : false,
                    vender_invoice_date: materialLog.vender_invoice_date !== null ? dayjs(new Date(materialLog.vender_invoice_date)).format('YYYY-MM-DD H:mm:ss') : null,
                    vender_invoice_number: materialLog.vender_invoice_number,
                    vender_invoice_value: materialLog.vender_invoice_value
                })
            }
        }

        const dbObj = {
            id: materialLog.id,
            detail: details
        }
        await Auth.hitEndpointNew("PATCH", "schedule", "", dbObj);

        const tempMaterialLogs = [...materialLogs];
        const index = tempMaterialLogs.findIndex(ml => parseInt(ml.id) === parseInt(materialLog.id));
        // Updates current table with new data
        if(index !== -1){
            tempMaterialLogs[index] = materialLog;
            setMaterialLogs([...tempMaterialLogs]);
        }

        setError({
            type: '',
            message: ''
        });
        setEditMaterialLog(null);
    }

    const handleCloseNoSave = () => {
        setError({
            type: '',
            message: ''
        });
        setEditMaterialLog(null);
    }

    return (
        <Grid
            sx={{
                display: 'grid',
                margin: '10px 0 0 10px'
            }}
        >
            <FormGroup sx={{ 
                display: 'grid',
                width: '400px',
                border: '2px solid rgb(220, 220, 220)',
                borderRadius: '3px',
                marginBottom: '10px'
            }}>
                <Grid sx={{
                    fontSize: '20px', 
                    textAlign: 'center', 
                    fontWeight: 'bold',
                    padding: '8px 0'}}
                >Filters</Grid>
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '49'
                            }
                        },
                    }}
                    placeholder="Select Material Type"
                    value={filters.materialType}
                    onChange={(materialType) => setFilters({...filters, materialType: materialType})}
                    options={materialTypes}
                    isClearable
                    isSearchable
                />
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '48'
                            }
                        },
                    }}
                    placeholder="Select Job"
                    value={filters.job}
                    onChange={handleJobSelect}
                    options={jobs}
                    isClearable
                    isSearchable
                />
                <Select
                    styles={{
                        container: styles => {
                            return {
                                ...styles,
                                width: '80%',
                                justifySelf: 'center',
                                margin: '5px 0',
                                zIndex: '47'
                            }
                        },
                    }}
                    placeholder="Select Lot"
                    value={filters.lot}
                    onChange={(lot) => setFilters({...filters, lot: lot})}
                    options={lots}
                    isClearable
                    isSearchable
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, scheduledDate: null});
                                return;
                            }
                            setFilters({...filters, scheduledDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Scheduled Date"
                        selected={filters.scheduledDate}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(date) => {
                            if(date === null){
                                setFilters({...filters, completedDate: null});
                                return;
                            }
                            setFilters({...filters, completedDate: new Date(date)})
                        }}
                        sx={{
                            width: '80%',
                            justifySelf: 'center',
                            margin: '6px 0'
                        }}
                        slotProps={{
                            textField: {
                                size: 'small'
                            },
                        }}
                        label="Completed Date"
                        selected={filters.completedDate}
                    />
                </LocalizationProvider>
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.builder_invoiced} 
                        onChange={(e) => setFilters({...filters, builder_invoiced: e.target.checked})}/>} 
                    label="Builder Invoiced" 
                />
                <FormControlLabel 
                    sx={{
                        justifySelf: 'center'
                    }}
                    control={<Switch 
                        checked={filters.vender_invoiced} 
                        onChange={(e) => setFilters({...filters, vender_invoiced: e.target.checked})}/>} 
                    label="Vender Invoiced" 
                />
                <Button 
                    sx={{
                        justifySelf: 'center',
                        width: '200px',
                        margin: '10px auto 15px'
                    }}
                    disabled={disableFilterButton}
                    variant='contained'
                    onClick={handleUpdateTable}
                >Update Table</Button>
            </FormGroup>
            <MaterialReactTable
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                columns={columns}
                data={materialLogs}
                enableSorting
                layoutMode="grid-no-grow"
                enableColumnResizing
                enableStickyHeader
                muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                        cursor: canEditInvoice ? cursor : 'default',
                    },
                    onMouseOver: () => {
                        if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed' ||
                            cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                            cell.column.id === 'vender_invoice_complete'){
                            setCursor('pointer');
                        }
                        else{
                            setCursor('default');
                        }
                    },
                    onClick: () => {
                        if(!canEditInvoice){
                            return;
                        }

                        const materialLog = materialLogs.find(ml => parseInt(ml.id) === parseInt(cell.row.original.id));
                        if(materialLog !== undefined){
                            if(cell.column.id === 'builder_invoice_number' || cell.column.id === 'builder_billed'){
                                materialLog.dialog = 'builder';
                                setEditMaterialLog(materialLog);
                            }
                            if(cell.column.id === 'vender_invoice_number' || cell.column.id === 'vender_invoice_value' ||
                                cell.column.id === 'vender_invoice_complete'){
                                materialLog.dialog = 'vender';
                                setEditMaterialLog(materialLog);
                            }
                        }
                    }
                })}
                initialState={{
                    pagination: {pageSize: 100, pageIndex: 0},
                    columnPinning: {left: ['job_number', 'lot_number', 'phase_task']}
                }}
                renderTopToolbarCustomActions={({table}) => {
                    return (
                        <Grid sx={{
                            display: 'flex',
                            width: "100%",
                            justifyContent: "center",
                            fontSize: "30px",
                            fontWeight: "bold",
                            cursor: "default"
                            }}
                        >
                            Material Usage Log
                        </Grid>
                    );
                }}
            />
            {editMaterialLog !== null && 
            <EditInvoiceDialog 
                materialLog={editMaterialLog} 
                closeWithSave={handleCloseWithSave} 
                closeNoSave={handleCloseNoSave}
            />}
            <Dialog open={error.type !== ''}>
                <DialogTitle sx={{backgroundColor: 'rgb(255, 150, 150)', fontWeight: 'bold'}}>{error.type}</DialogTitle>
                <DialogContent 
                    sx={{width: "600px", backgroundColor: 'rgb(255, 150, 150)'}}>{error.message}</DialogContent>
                <DialogActions sx={{backgroundColor: 'rgb(255, 150, 150)'}}>
                    <Button 
                        sx={{
                            backgroundColor: 'rgb(150, 150, 255)'
                        }}
                        variant='contained'
                        onClick={() => {
                            setError({
                                type: '',
                                message: ''
                            })
                        }}
                    >OK</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const EditInvoiceDialog = ({materialLog, closeWithSave, closeNoSave}) => {
    const [materialLogToEdit, setMaterialLogToEdit] = useState({...materialLog});

    return (
        <Dialog open={materialLogToEdit !== null}>
            {materialLogToEdit !== null && materialLogToEdit.dialog === 'builder' ? 
            <DialogTitle style={{borderBottom: "2px solid black"}}>Material Usage Log Builder Invoice</DialogTitle> : null}
            {materialLogToEdit !== null && materialLogToEdit.dialog === 'vender' ?
            <DialogTitle style={{borderBottom: "2px solid black"}}>Material Usage Log Vender Invoice</DialogTitle> : null}
            {materialLogToEdit &&
            <DialogContent>
                <InfoBox label='Material Amount:' value={materialLogToEdit.material + " " + materialLogToEdit.materialUnit}/>
                <InfoBox label='Supplier:' value={materialLogToEdit.supplier}/>
                <InfoBox label='Pump:' value={materialLogToEdit.pump}/>
                <InfoBox label='Time:' value={materialLogToEdit.time}/>
                <InfoBox label='Order #:' value={materialLogToEdit.order}/>
                {materialLogToEdit.dialog === 'builder' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                        }}
                        placeholder="Builder Invoice #"
                        value={materialLogToEdit.builder_invoice_number !== null ? materialLogToEdit.builder_invoice_number : ""}
                        onChange={(e) => setMaterialLogToEdit({...materialLogToEdit, builder_invoice_number: e.target.value})}
                    />
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                        }}
                        control={<Checkbox 
                            defaultChecked={materialLogToEdit.builder_billed} 
                            onChange={async (e) => {
                                if(!e.target.checked){
                                    setMaterialLogToEdit({...materialLogToEdit, 
                                        builder_billed: e.target.checked, 
                                        builder_invoice_number: null,
                                        builder_invoice_date: null,
                                        builder_invoice_by: null
                                    });
                                    return;
                                }
                                setMaterialLogToEdit({...materialLogToEdit, 
                                    builder_billed: e.target.checked, 
                                    builder_invoice_date: dayjs(new Date()).format('MM-DD-YYYY'),
                                    builder_invoice_by: await Auth.getUserId()
                                });
                            }}
                        />} 
                        label="Builder Billed" 
                    />
                </DialogContent>}
                {materialLogToEdit.dialog === 'vender' &&
                <DialogContent>
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                        }}
                        placeholder="Vender Invoice #"
                        value={materialLogToEdit.vender_invoice_number !== null ? materialLogToEdit.vender_invoice_number : ""}
                        onChange={(e) => setMaterialLogToEdit({...materialLogToEdit, vender_invoice_number: e.target.value})}
                    />
                    <TextField
                        sx={{
                            width: '100%',
                            margin: '5px auto',
                        }}
                        placeholder="Vender Invoice Yards"
                        value={materialLogToEdit.vender_invoice_value !== null ? materialLogToEdit.vender_invoice_value : ""}
                        onChange={(e) => setMaterialLogToEdit({...materialLogToEdit, vender_invoice_value: e.target.value})}
                    />
                    <FormControlLabel 
                        sx={{
                            justifySelf: 'center',
                        }}
                        control={<Checkbox 
                            defaultChecked={materialLogToEdit.vender_invoice_complete} 
                            onChange={async (e) => {
                                if(!e.target.checked){
                                    setMaterialLogToEdit({...materialLogToEdit, 
                                        vender_invoice_complete: e.target.checked, 
                                        vender_invoice_number: null,
                                        vender_invoice_value: null,
                                        vender_invoice_date: null,
                                        vender_invoice_by: null
                                    });
                                    return;
                                }
                                setMaterialLogToEdit({...materialLogToEdit, 
                                    vender_invoice_complete: e.target.checked, 
                                    vender_invoice_date: dayjs(new Date()).format('MM-DD-YYYY'),
                                    vender_invoice_by: await Auth.getUserId()
                                });
                        }}
                        />} 
                        label="Vender Invoice Complete" 
                    />
                </DialogContent>}
            </DialogContent>}
            <DialogActions>
                <Button onClick={() => closeWithSave(materialLogToEdit)}>Save</Button>
                <Button onClick={closeNoSave}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

const InfoBox = ({label, value}) => {
    return (
        <Grid
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(10, 1fr)',
                margin: '10px auto'
            }}
        >
            <Grid
                sx={{
                    gridColumn: '1/6',
                    display: 'grid',
                    alignContent: 'center',
                    textAlign: 'end',
                    padding: '5px',
                }}
            >{label}</Grid>
            <Grid
                sx={{
                    gridColumn: '6/10',
                    display: 'grid',
                    alignContent: 'center',
                }}
            >{value}</Grid>
        </Grid>
    )
}

export default MaterialUsageLog;
